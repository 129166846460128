import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import TitleRow from "../../shared/TitleRow";
import AuditCard from "./AuditCard";

export class AuditSection extends Component {
  state = {
    isOpen: true,
  };

  render() {
    return (
      <>
        <Row noGutters>
          <Col>
            <Row noGutters>
              <Col>
                <TitleRow
                  view="audit"
                  text={this.props.audit.industry_type}
                  passRate={this.props.audit.pass_data.passed}
                  total={this.props.audit.pass_data.total}
                  show={this.state.isOpen}
                  toggleShow={() =>
                    this.setState((prevState) => ({
                      isOpen: !prevState.isOpen,
                    }))
                  }
                />
              </Col>
            </Row>
            <Row className={this.state.isOpen ? "" : "hide"}>
              {this.props.audit.questionnaires[0].questions.map(
                (question, index) => (
                  <AuditCard
                    key={`audit_${this.props.audit.id}_question_${question.id}`}
                    question={question}
                    handleShow={this.props.handleShow}
                    index={index}
                  />
                )
              )}
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default AuditSection;
