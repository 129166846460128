export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CHANGE_VIEW = "CHANGE_VIEW";
export const AUDIT_DATA = "AUDIT_DATA";
export const PAGE = "PAGE";
export const AUDITS_PAGE = "AUDITS_PAGE";
export const OUTLETS_PAGE = "OUTLETS_PAGE";
export const USERS_PAGE = "USERS_PAGE";
export const FIRST_PAGE = "FIRST_PAGE";
export const LAST_PAGE = "LAST_PAGE";
export const SET_LOCATIONS_LIST = "SET_LOCATIONS_LIST";
