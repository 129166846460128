const pagify = (array, size = 10) => {
  if (!array) return [];
  const firstPage = array.slice(0, size);
  if (!firstPage.length) {
    return array;
  }
  return [firstPage].concat(pagify(array.slice(size, array.length), size));
};

export default pagify;
