import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteModal = (props) => {
    return (
        <Modal show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Deleted entries cannot be retrieved. Confirm that you still want to delete the selcted entry.</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={props.handleDelete}>
                    Confirm Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal;