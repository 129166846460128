import React from "react";
import { Col, Row } from "react-bootstrap";
import AuditStatus from "./AuditStatus";
import MCFStandard from "./MCFStandard";

const StatusRow = ({data}) => {
  return (
    <Col className="dashboard-row">
      <Row>
        <Col xs="auto" md="3" xl="2" className="dashboard-card">
          <div className="dashboard-header">
            <p>Audit Status</p>
          </div>
          <AuditStatus audits={data.audits} />
        </Col>
        <Col className="dashboard-card">
          <div className="dashboard-header">
            <p>Most Commonly Failed Standards</p>
          </div>
          <Row className="mcfs-row">
            <MCFStandard title="Marine" data={data.questions.outboard_engines}/>
            <MCFStandard title="Industrial" data={data.questions.commercial_industrial} />
            <MCFStandard title="Lawn and Garden" data={data.questions.lawn_and_garden} />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default StatusRow;
