import React, { Component } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Views from "./views";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Views />
      </BrowserRouter>
    );
  }
}

export default App;