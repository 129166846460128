import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import Request from '../utils/request';
import {withRouter} from 'react-router-dom';

const ForgotPassword = (props) => {
  return (
    <div className="Login-form-container">
      <img
        className="Login-form-container__logo"
        src="https://placehold.it/150x60"
        alt=""
      />
      <div className="login-confirm-text">
        <p>Confirm your email address to request a new password.</p>
      </div>
      <Form>
        <FormGroup controlId="formEmail">
          <FormLabel className="login-label">Email address</FormLabel>
          <FormControl
              type="email"
              placeholder="Email address"
              onChange={e => props.onChange(e)}
          />
        </FormGroup>
        <Row>
          <Col>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              className="float-right w-50"
              onClick={e => props.onClick(e)}
            >
              Request New Password
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

class ChangePasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  sendForgotPassword = (e) => {
    e.preventDefault();
    Request.post('auth/forgotPassword', this.state).then((response) => {
      if(response.status === 'fail' || response.status === 'error')
      {
        alert('There was an error sending your password reset request, please check your email before trying again.')
      }

      if(response.status === 'success')
      {
        alert('A password reset email request has been sent.');
        this.props.history.push('/');
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  render() {
    return (
      <Container className="Login-container">
        <ForgotPassword
            onChange={e => this.handleChange(e)}
            onClick={(e) => this.sendForgotPassword(e)}
        />
      </Container>
    );
  }
}

export default withRouter(ChangePasswordComponent);
