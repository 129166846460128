import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  FormCheck
} from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import {
  DeleteLocationButton,
  SaveChangesButton,
  AddLocationButton,
  AddUserButton,
  DeleteUserButton
} from "../shared/buttons";

const mapStateToProps = state => {
  return {
    state: state
  };
};

const Location = props => {
  return (
    <Col>
      <Row noGutters className="dashboard-header">
        <Col>
          <Row noGutters>
            <p className="black-title">Outlet Locations</p>
            <span className="angle-right">
              <FaAngleRight />
            </span>
            <p className="red-title">Location</p>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col xs="6" className="edit-container">
          <Form>
            <FormGroup controlId="formGroupOutletName">
              <FormLabel>Outlet Name</FormLabel>
              <FormControl type="text" placeholder="Outlet Name"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupAddress1">
              <FormLabel>Address 1</FormLabel>
              <FormControl type="text" placeholder="Address 1"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupTown">
              <FormLabel>Town</FormLabel>
              <FormControl type="text" placeholder="Town"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupCounty">
              <FormLabel>County</FormLabel>
              <FormControl type="text" placeholder="County"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupPostCode">
              <FormLabel>Post Code</FormLabel>
              <FormControl type="text" placeholder="Post Code"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupRegion">
              <FormLabel>Region</FormLabel>
              <FormControl as="select">
                <option>Choose a region...</option>
                <option>...</option>
              </FormControl>
            </FormGroup>
          </Form>
          <Form className="validation-loc">
            <FormLabel>Validate Location</FormLabel>
            <FormCheck
              custom
              label="I confirm that the above details are correct for this location"
            />
          </Form>
          <Row noGutters className="bottom-buttons">
            <Col xs="6">
              <DeleteLocationButton />
            </Col>
            <Col xs="6" className="save-change">
              <SaveChangesButton />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const EditLocation = props => {
  return (
    <Col>
      <Row noGutters className="dashboard-header">
        <Col>
          <Row noGutters>
            <p className="black-title">Outlet Locations</p>
            <span className="angle-right">
              <FaAngleRight />
            </span>
            <p className="red-title">Edit Location</p>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col xs="6" className="edit-container">
          <Form>
            <FormGroup controlId="formGroupOutletName">
              <FormLabel>Outlet Name</FormLabel>
              <FormControl type="text" placeholder="Outlet Name"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupAddress1">
              <FormLabel>Address 1</FormLabel>
              <FormControl type="text" placeholder="Address 1"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupTown">
              <FormLabel>Town</FormLabel>
              <FormControl type="text" placeholder="Town"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupCounty">
              <FormLabel>County</FormLabel>
              <FormControl type="text" placeholder="County"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupPostCode">
              <FormLabel>Post Code</FormLabel>
              <FormControl type="text" placeholder="Post Code"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupRegion">
              <FormLabel>Region</FormLabel>
              <FormControl as="select">
                <option>Choose a region...</option>
                <option>...</option>
              </FormControl>
            </FormGroup>
          </Form>
          <Form className="validation-loc">
            <FormLabel>Validate Location</FormLabel>
            <FormCheck
              custom
              label="I confirm that the above details are correct for this location"
            />
          </Form>
          <Row noGutters className="bottom-buttons">
            <Col xs="6">
              <DeleteLocationButton />
            </Col>
            <Col xs="6" className="save-change">
              <SaveChangesButton />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const AddNewLocation = () => {
  return (
    <Col>
      <Row noGutters className="dashboard-header">
        <Col>
          <Row noGutters>
            <p className="black-title">Outlet Locations</p>
            <span className="angle-right">
              <FaAngleRight />
            </span>
            <p className="red-title">Add New Location</p>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col xs="6" className="edit-container">
          <Form>
            <FormGroup>
              <FormLabel>Outlet Name</FormLabel>
              <FormControl type="text" placeholder="Outlet Name"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Address 1</FormLabel>
              <FormControl type="text" placeholder="Address 1"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Town</FormLabel>
              <FormControl type="text" placeholder="Town"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>County</FormLabel>
              <FormControl type="text" placeholder="County"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Post Code</FormLabel>
              <FormControl type="text" placeholder="Post Code"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Region</FormLabel>
              <Form.Control as="select">
                <option>Choose a region...</option>
                <option>...</option>
              </Form.Control>
            </FormGroup>
          </Form>
          <Form className="validation-loc">
            <FormLabel>Validate Location</FormLabel>
            <FormCheck
              custom
              label="I confirm that the above details are correct for this location"
            />
          </Form>
          <Row noGutters className="bottom-buttons">
            <Col className="save-change">
              <AddLocationButton />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const AddNewUser = () => {
  return (
    <Col>
      <Row noGutters className="dashboard-header">
        <Col>
          <Row noGutters>
            <p className="black-title">Users</p>
            <span className="angle-right">
              <FaAngleRight />
            </span>
            <p className="red-title">Add New User</p>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col lg="6" className="edit-container">
          <Form>
            <FormGroup>
              <FormLabel>First Name</FormLabel>
              <FormControl type="text" placeholder="First Name"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Surname</FormLabel>
              <FormControl type="text" placeholder="Surname"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Email Address</FormLabel>
              <FormControl
                type="email"
                placeholder="Email Address"
              ></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Password</FormLabel>
              <FormControl type="password" placeholder="Password"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Confirm Password</FormLabel>
              <FormControl
                type="password"
                placeholder="Confirm Password"
              ></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Country/Region</FormLabel>
              <Form.Control as="select">
                <option>GB - Great Britain</option>
                <option>...</option>
              </Form.Control>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Area</FormLabel>
              <Form.Control as="select">
                <option>Scotland</option>
                <option>...</option>
              </Form.Control>
            </FormGroup>
          </Form>
          <Row noGutters className="bottom-buttons">
            <Col className="save-change">
              <AddUserButton />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const EditUser = props => {
  return (
    <Col>
      <Row noGutters className="dashboard-header">
        <Col>
          <Row noGutters>
            <p className="black-title">Users</p>
            <span className="angle-right">
              <FaAngleRight />
            </span>
            <p className="red-title">Edit User</p>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col lg="6" className="edit-container">
          <Form>
            <FormGroup controlId="formGroupFirsttName">
              <FormLabel>First Name</FormLabel>
              <FormControl type="text" placeholder="First Name"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupSurname">
              <FormLabel>Surname</FormLabel>
              <FormControl type="text" placeholder="Surname"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formEmail">
              <FormLabel>Email</FormLabel>
              <FormControl type="email" placeholder="Email"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupPassword">
              <FormLabel>Password</FormLabel>
              <FormControl type="password" placeholder="Password"></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupConfirmPassword">
              <FormLabel>Confirm Password</FormLabel>
              <FormControl
                type="password"
                placeholder="Confirm Password"
              ></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Country/Region</FormLabel>
              <Form.Control as="select">
                <option>GB - Great Britain</option>
                <option>...</option>
              </Form.Control>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <FormLabel>Area</FormLabel>
              <Form.Control as="select">
                <option>Scotland</option>
                <option>...</option>
              </Form.Control>
            </FormGroup>
          </Form>
          <Row noGutters className="bottom-buttons">
            <Col xs="6">
              <DeleteUserButton />
            </Col>
            <Col xs="6" className="save-change">
              <SaveChangesButton />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const returnView = props => {
  let paramId = props.match.params.id;

  if (props.version === "location") {
    return <Location id={paramId} />;
  }
  if (props.version === "edit-location") {
    return <EditLocation {...props} />;
  }
  if (props.version === "add-new-location") {
    return <AddNewLocation />;
  }
  if (props.version === "add-new-user") {
    return <AddNewUser />;
  }
  if (props.version === "edit-user") {
    return <EditUser {...props} />;
  }
};

class EditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Col className="view-col">{returnView(this.props)}</Col>;
  }
}

export default withRouter(connect(mapStateToProps)(EditPage));
