import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Table, Col, Row, Button } from "react-bootstrap";
import Pagination from "../../utils/Pagination";
import DashboardHeader from "../../shared/DashboardHeader";
import pagify from "../../utils/pagify";
import TitleRow from "../../shared/TitleRow";
import { connect } from "react-redux";
import { outletsPage, setLocationsList } from "../../actions";
import { FaExclamationCircle } from "react-icons/fa";
import Request from "../../utils/request";

const mapStateToProps = state => {
  return {
    pagination: state.pagination,
    locations: state.locations
  };
};

export const EditButton = props => {
  const history = useHistory();

  return (
    <Button
      className="action-btn edit-btn"
      onClick={() => props.onClick(history, "location", props.data)}
    >
      Edit
    </Button>
  );
};

const OutletView = props => {
  let filteredLocations = props.locations.slice();

  if (props.currentRegion.id !== 0) {
    filteredLocations = filteredLocations.filter(
      location => location.area.region.id === props.currentRegion.id
    );
  }

  switch(props.currentOutlet)
  {
    case 'Validated Outlets':
      filteredLocations = filteredLocations.filter((location) => {
        if(location.approved) return location;
      });
      break;
    case 'Unvalidated Outlets':
      filteredLocations = filteredLocations.filter((location) => {
        if(!location.approved) return location;
      });
      break;
    default:
  }

  const pagified = pagify(filteredLocations, 15);
  const emptyMsg = "No outlets match your selected filters";
  const { outletsPage } = props.pagination;
  const pageLen = pagified.length;

  const TableRow = props => {
    return (
      <>
        <td>
          {!props.location.approved ? (
            <FaExclamationCircle className="flag" />
          ) : null}
        </td>
        <td>{props.location.name}</td>
        <td>{props.location.address_1}</td>
        <td>{props.location.town}</td>
        <td>{props.location.county}</td>
        <td>{props.location.post_code}</td>
        <td>{props.location.area.region.code}</td>
        <td>
          <EditButton data={props.location} onClick={changeView} />
        </td>
      </>
    );
  };

  const pageList = page => {
    let list = [];
    let data = pagified;

    const number = page - 1;
    list = data[number];

    return list;
  };

  const changeView = (history, path, data) => {
    history.push(`${path}${data && data.id ? "/" + data.id : ""}`);
  };

  const currentPage = pageList(outletsPage);
  return (
    <Col className="view-col">
      <DashboardHeader
        view="outlets"
        changeView={changeView}
        text="Outlet Locations"
        withDropdowns={true}
        type="outlet"
        exportData={props.exportData}
        regions={props.regions}
        currentRegion={props.currentRegion}
        currentOutlet={props.currentOutlet}
        setCurrentRegion={props.setCurrentRegion}
        setCurrentOutlet={props.setCurrentOutlet}
        getPage={props.getPage}
      />
      <Table responsive hover size="sm">
        <TitleRow view="outlets" />
        <tbody className="data-body">
          {currentPage && currentPage.length !== 0
            ? currentPage.map(location => {
                return (
                  <tr key={`location_${location.id}`}>
                    <TableRow
                      location={location}
                      changeView={changeView}
                      page={outletsPage}
                    />
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
      {currentPage && currentPage.length === 0 ? (
        <p class="u-center u-padding-md">{emptyMsg}</p>
      ) : (
        ""
      )}
      <Row noGutters>
        <Pagination
          data={pagified}
          onClick={props.getPage}
          page={outletsPage}
          firstPage={props.firstPage}
          lastPage={props.lastPage}
          pageLen={pageLen}
        />
      </Row>
    </Col>
  );
};

class OutletsViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRegion: { id: 0 },
      currentOutlet: 'All Outlets',
      locations: [],
      regions: [],
      exportData: []
    };
    this.getPage = this.getPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
  }

  setLocations = async () => {
    const locations = await Request.get("locations");

    const exportData = locations.data.map((location) => {
        return {
            "Outlet Name": location.name,
            "Approved": location.approved ? '✅' : '❌',
            "Address": location.address_1,
            "Town": location.town,
            "County": location.county,
            "Post Code": location.post_code,
            "Region": location.area.region.name,
            "Area": location.area.name,
        }
    });

    if(!locations)
    {
      // TODO: Redirect back to login
      // console.log('Auth Failed:', locations);
      return;
    }

    this.setState({
      locations: locations.data,
      exportData
    });

    this.props.dispatch(setLocationsList(locations.data));
  };

  setRegions = async () => {
    const regions = await Request.get("regions");

    if(!regions)
    {
      // TODO: Redirect back to login
      // console.log('Auth Failed:', locations);
      return;
    }

    this.setState({
      regions: regions.data
    });

    this.props.dispatch(setLocationsList(regions.data));
  };

  getPage(page) {
    this.props.dispatch(outletsPage(page));
  }

  lastPage(lastPage) {
    this.props.dispatch(outletsPage(lastPage));
  }

  firstPage() {
    this.props.dispatch(outletsPage(1));
  }

  componentDidMount = async () => {
    await this.setLocations();
    await this.setRegions();
  };

  setCurrentRegion(region) {
    this.setState({
      currentRegion: region ? region : { id: 0 }
    });
  }

  setCurrentOutlet(outlet) {
    this.setState({
      currentOutlet: outlet ? outlet : 'All Outlets'
    });
  }

  render() {
    return (
      <OutletView
        exportData={this.state.exportData}
        getPage={this.getPage}
        firstPage={this.firstPage}
        lastPage={this.lastPage}
        pagination={this.props.pagination}
        locations={this.state.locations}
        regions={this.state.regions}
        history={this.props.history}
        currentRegion={this.state.currentRegion}
        currentOutlet={this.state.currentOutlet}
        setCurrentRegion={(region) => {this.setCurrentRegion(region)}}
        setCurrentOutlet={(outlet) => {this.setCurrentOutlet(outlet)}}
      />
    );
  }
}

export default connect(mapStateToProps)(OutletsViewComponent);
