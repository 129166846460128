import React from "react";
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";

const ForgotPassword = () => {
  return (
    <div className="Login-form-container">
      <img
        className="Login-form-container__logo"
        src="https://placehold.it/150x60"
        alt=""
      />
      <div className="login-confirm-text">
        <p>Confirm your email address to request a new password.</p>
      </div>
      <Form>
        <FormGroup controlId="formEmail">
          <FormLabel className="login-label">Email address</FormLabel>
          <FormControl type="email" placeholder="Email address" />
        </FormGroup>
        <Row>
          <Col>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              className="float-right w-50"
            >
              Request New Password
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ForgotPassword;
