import React, { Component } from "react";
import { connect } from "react-redux";
import {Col, Row, Modal, Button} from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import AuditSection from './AuditSection';
import ReactToPrint from "react-to-print";

import request from "../../utils/request";
import { HeaderButton } from "../../shared/buttons";
import moment from "moment";
import {CSVLink} from "react-csv";

const mapStateToProps = state => {
  return {
    audit_data: state.audit_data
  };
};

class Audit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audits: [],
      location: null,
      marine: false,
      industrial: false,
      lawn: false,
      show: false,
      selectedImage: null,
      exportData: []
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  componentDidMount = async() => {
    const response = await request.get(`audits/${this.props.match.params.id}`);
    console.log('RESPONSE:', response.data[0]);

    // Create Export Data
    let exportData = response.data[0].audits.map((audit) => {
        return audit.questionnaires[0].questions.map((question) => {
            return {
                'Order Number': question.orderNo,
                'Question Title': question.name,
                'Standard Met': question.passed ? '✅' : '❌',
                'Industry Type': audit.industry_type,
                'Description': question.description,
                'Reason': question.reason,
                'Evidence Required': question.evidence_required,
                'Rectification Period': question.rectification_period,
                'Category': question.category,
                'Sub Category': question.sub_category,
                'Notes': question.notes,
                'Original Standard': question.original_standard,
                'Support Tools': question.support_tools,
            }
        })
    });

    exportData = [].concat(...exportData);

    if(response.data[0]) this.setState({
      audits: response.data[0].audits,
      exportData
    });
    delete response.data[0].pass_data;
    // delete response.data[0].audits;
    this.setState({
        location: response.data[0],
    });
    const printString = this.props.location.search;

    // Check if printing is needed according to url params
    if(printString && printString.includes('print')){
      if (printString.includes('true')){
        document.getElementById('btn-print-audit').click();
      }
    }
  }

  handleShow(selectedImage) {
    this.setState({
      show: true,
      selectedImage
    });
  }

  handleClose() {
    this.setState({
      show: false,
      selectedImage: null
    });
  }

  toggleShow(show) {
    if (show === "Marine") {
      this.setState(prevState => ({
        marine: !prevState.marine
      }));
    }

    if (show === "Industrial") {
      this.setState(prevState => ({
        industrial: !prevState.industrial
      }));
    }

    if (show === "Lawn and Garden") {
      this.setState(prevState => ({
        lawn: !prevState.lawn
      }));
    }
  }

  render() {
    if(!this.state.location) return null;

    const { data } = this.props.audit_data;
    return (
      <Col className="view-col" ref={el => (this.componentRef = el)}>
        <Col>
          <Row noGutters className="dashboard-header">
            <Col xs="auto" className="dashboard-header-col">
              <Row noGutters className="dashboard-header-col__row">
                <p className="black-title">Audits</p>
                <span className="angle-right">
                  <FaAngleRight />
                </span>
                <p className="red-title">Audit View</p>
                <Col>
                  <Row className="flag-header" noGutters>
                    <p>{`${this.state.location.name}, ${this.state.location.address_1}, ${this.state.location.town}, ${this.state.location.post_code}`}</p>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              <Row className="last-buttons">
                <Col xs="auto">
                  <ReactToPrint
                      trigger={() => <HeaderButton id="btn-print-audit" text="Print Audit" />}
                      content={() => this.componentRef}
                  >
                  </ReactToPrint>
                </Col>
                <Col xs="auto">
                  <CSVLink filename={`${this.state.location.name}, ${this.state.location.address_1}, ${this.state.location.town}, ${this.state.location.post_code} - `+moment(this.state.audits[0].timestamp).format('YYYY-MM-DD')+".csv"} style={{"backgroundColor": "rgba(0,0,0,0)"}} data={this.state.exportData} target="_blank" >
                    <Button size="sm" className="export-btn">
                      Export Data
                    </Button>
                  </CSVLink>
                </Col>
              </Row>
            </Col>
          </Row>

          {
            this.state.audits.map((audit) => (
              <AuditSection 
                audit={audit} 
                key={`auditSection_${audit.id}`} 
                handleShow={this.handleShow}
              />
            ))
          }

          <Modal
            centered
            show={this.state.show}
            onHide={this.handleClose}
            className="image-modal"
            size="lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <img src={`data:image/png;base64, ${this.state.selectedImage}`} className="modal-image" alt="" />
            </Modal.Body>
          </Modal>
        </Col>
      </Col>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Audit));
