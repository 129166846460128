import React from "react";
import { Col } from "react-bootstrap";
import "./Navigation.css";
import { Nav, NavItem } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";

function LogoutButton() {
  let history = useHistory();
  return (
    <NavItem key="navLogout">
      <NavLink
        className="nav-button"
        to="/"
        exact
        onClick={() => {
          localStorage.removeItem("token");
          history.push("/login");
        }}
      >
        Log Out
      </NavLink>
    </NavItem>
  );
}

const Navigation = props => {
  const navList = [
    ["Dashboard", "/dashboard"],
    ["Audits", "/audits"],
    ["Outlet Locations", "/outlet-locations"],
    ["Users", "/users"],
    ["Support", "/support"]
  ].map((pageItem, index) => {
    return (
      <NavItem key={`navItem${index}`}>
        <NavLink className="nav-button" to={pageItem[1]} exact>
          {pageItem[0]}
        </NavLink>
      </NavItem>
    );
  });

  return (
    <Col xs={2} className="nav-col">
      <img className="nav-col__logo" src={require("../resources/images/honda-logo-white.png")} alt="" />
      <Nav as="nav">
        {navList}
        <LogoutButton />
      </Nav>
    </Col>
  );
};

export default Navigation;
