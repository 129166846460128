import * as actions from "../constants/action-types";

export const forgotPassword = () => {
  return {
    type: actions.FORGOT_PASSWORD
  };
};

export const changeView = view => {
  return {
    type: actions.CHANGE_VIEW,
    payload: view
  };
};

export const auditData = data => {
  return {
    type: actions.AUDIT_DATA,
    payload: data
  };
};

export const auditsPage = page => {
  return {
    type: actions.AUDITS_PAGE,
    payload: page
  };
};

export const outletsPage = page => {
  return {
    type: actions.OUTLETS_PAGE,
    payload: page
  };
};

export const usersPage = page => {
  return {
    type: actions.USERS_PAGE,
    payload: page
  };
};

export const firstPage = () => {
  return {
    type: actions.FIRST_PAGE
  };
};

export const lastPage = page => {
  return {
    type: actions.LAST_PAGE,
    payload: page
  };
};

export const setLocationsList = locations => {
  return {
    type: actions.SET_LOCATIONS_LIST,
    payload: locations
  };
};
