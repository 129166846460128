import { combineReducers } from "redux";
import * as ActionTypes from "../constants/action-types";

/* import locations_list from "./locations"; */

const loginState = {
  forgotPassword: false
};

const navState = {
  view: null
};

const auditState = {
  data: {}
};

const pageState = {
  auditsPage: 1,
  outletsPage: 1,
  usersPage: 1
};

const locationsState = [];

export const login = (state = loginState, action) => {
  switch (action.type) {
    case "FORGOT_PASSWORD":
      return {
        forgotPassword: !state.forgotPassword
      };
    default:
      return state;
  }
};

const nav = (state = navState, action) => {
  switch (action.type) {
    case "CHANGE_VIEW":
      return {
        view: action.payload
      };
    default:
      return state;
  }
};

const audit_data = (state = auditState, action) => {
  switch (action.type) {
    case "AUDIT_DATA":
      return {
        data: action.payload
      };
    default:
      return state;
  }
};

const pagination = (state = pageState, action) => {
  switch (action.type) {
    case "AUDITS_PAGE":
      return { ...state, auditsPage: action.payload };
    case "OUTLETS_PAGE":
      return { ...state, outletsPage: action.payload };
    case "USERS_PAGE":
      return { ...state, usersPage: action.payload };
    default:
      return state;
  }
};

const locations_list = (state = locationsState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LOCATIONS_LIST:
      return action.payload;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  login,
  nav,
  audit_data,
  pagination,
  locations: locations_list
});

export default rootReducer;
