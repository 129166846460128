import React from "react";
import { Col, Row, ListGroup, ListGroupItem } from "react-bootstrap";

const MCFStandard = props => {
  const title = props.title;
  const list = props.data.map((item, index) => {
    return (
      <ListGroupItem key={index}>
        <Row noGutters>
          <Col xs="auto">{item.orderNo}</Col>
          <Col className="question-text">{item.name}</Col>
          <Col xs="auto">{item.percentage}%</Col>
        </Row>
      </ListGroupItem>
    );
  });

  return (
    <Col className="inner-col" md="4">
      <div className="data-title">
        <p>{title}</p>
      </div>
      <div className="mcfs data-body">
        {list && list.length !== 0 ? (
          <ListGroup>{list}</ListGroup>
        ) : (
          <Col className="empty">
            <p>Not enough data</p>
          </Col>
        )}
      </div>
    </Col>
  );
};

export default MCFStandard;
