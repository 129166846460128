import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import "./buttons.css";

export const AddLocationButton = props => {
  return (
    <Button
      className="btn--primary"
      onClick={() => {
        props.handleSubmit();
      }}
    >
      Add Location
    </Button>
  );
};

export const DeleteLocationButton = props => {
    return <Button {...props} className="delete-loc-btn">Delete Location</Button>;
};

export const AddUserButton = props => {
    return <Button {...props} className="btn--primary">Add This User</Button>;
};

export const DeleteUserButton = props => {
    return <Button {...props} className="delete-loc-btn">Delete User</Button>;
};

export const SaveChangesButton = props => {
  return (
    <Button
      className="btn--primary"
      onClick={() => {
        props.handleSubmit();
      }}
    >
      Save Changes
    </Button>
  );
};

export const ViewButton = props => {
  return (
    <Button
      className="action-btn view-btn"
      onClick={() => props.onClick("edit-user", props.data)}
    >
      View
    </Button>
  );
};

export const TripleButton = props => {
  return (
    <ButtonGroup className="triple">
      <Button
        className="action-btn"
        onClick={() => props.onClick("audit", props.data)}
      >
        View
      </Button>
      <Button className="action-btn">Print</Button>
      <Button className="action-btn" onClick={() => props.handleShow()}>
        Delete
      </Button>
    </ButtonGroup>
  );
};

export const HeaderButton = props => {
  return <Button {...props} className="header-btn">{props.text}</Button>;
};
