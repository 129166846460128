import React from "react";
import { Col, Row, Card, Button } from "react-bootstrap";

const AuditCard = (props) => {
  const passedText = props.question.passed ? "Yes" : "No";
  const headerColor = props.question.passed ? "#00803e" : "#cc0000";

  return (
    <Col className="audit-card" md="6">
      <div className="audit-header" style={{ backgroundColor: headerColor }}>
        <Col xs="auto">
          <span>1.{props.index + 1}</span>
          <span>{props.question.name}</span>
        </Col>
        <Col xs="auto">
          <span>Standard met:</span>
          <span>{passedText}</span>
        </Col>
      </div>
      <div className="audit-body">
        <Col className="audit-inner-col">
          <p>{props.question.description}</p>
          <p>{props.question.reason}</p>
          <p>{props.question.evidence_required}</p>
          <p>{props.question.rectification_period}</p>
          <p>{props.question.support_tools}</p>
          <p>{props.question.original_standard}</p>

          <Row noGutters className="audit-card__image-row">
            <Col xs="auto">
              <p className="photo-title">Photographic Evidence:</p>
            </Col>
            <Col xs="auto">
              <Row noGutters className="images-row">
                <Button
                  className="image-button"
                  onClick={() => props.handleShow(props.question.image_1)}
                >
                  <Card>
                    <Card.Img
                      src={`data:image/png;base64, ${props.question.image_1}`}
                      style={{ maxWidth: "100px" }}
                    />
                  </Card>
                </Button>
                <Button
                  className="image-button"
                  onClick={() => props.handleShow(props.question.image_2)}
                >
                  <Card>
                    <Card.Img
                      src={`data:image/png;base64, ${props.question.image_2}`}
                      style={{ maxWidth: "100px" }}
                    />
                  </Card>
                </Button>
                <Button
                  className="image-button"
                  onClick={() => props.handleShow(props.question.image_3)}
                >
                  <Card>
                    <Card.Img
                      src={`data:image/png;base64, ${props.question.image_3}`}
                      style={{ maxWidth: "100px" }}
                    />
                  </Card>
                </Button>
                <Button
                  className="image-button"
                  onClick={() => props.handleShow(props.question.image_4)}
                >
                  <Card>
                    <Card.Img
                      src={`data:image/png;base64, ${props.question.image_4}`}
                      style={{ maxWidth: "100px" }}
                    />
                  </Card>
                </Button>
              </Row>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Row noGutters className="no-padding">
                <Col className="bottom-title">Auditor Comments:</Col>
              </Row>
              <Row noGutters className="no-padding">
                <Col className="bottom-col auditor-col">
                  <p className="auditor-comments">{props.question.notes}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    </Col>
  );
};

export default AuditCard;
