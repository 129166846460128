import {
  Button,
  Col,
  DropdownButton,
  DropdownItem,
  Row,
} from "react-bootstrap";
import DatePicker from "../../utils/Datepicker";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";

const AuditHeader = (props) => {
  const [outletTitle, setOutletTitle] = useState("All Outlets");
  const [regionTitle, setRegionTitle] = useState("All Regions");
  const [auditTypeTitle, setAuditTypeTitle] = useState("All Audit Types");

  useEffect(() => {
    let outletTitle = "All Outlets";

    if (props.filters.validated === true) {
      outletTitle = "Validated Locations";
    } else if (props.filters.validated === false) {
      outletTitle = "Unvalidated Locations";
    }

    setOutletTitle(outletTitle);
  }, [props.filters.validated]);

  useEffect(() => {
    let regionTitle = "All Regions";

    if (props.filters.regionId > 0) {
      const region = props.regions.find(
        (region) => region.id === props.filters.regionId
      );
      if (region) {
        regionTitle = `${region.code} - ${region.name}`;
      }
    }
    setRegionTitle(regionTitle);
  }, [props.filters.regionId, props.regions]);

  useEffect(() => {
    let auditTypeTitle = "All Audit Types";

    if (props.filters.auditType === "Outboard Engines") {
      auditTypeTitle = "Marine";
    } else if (props.filters.auditType === "Commercial Industrial") {
      auditTypeTitle = "Industrial";
    } else if (props.filters.auditType === "Lawn and Garden") {
      auditTypeTitle = "L&G";
    }

    setAuditTypeTitle(auditTypeTitle);
  }, [props.filters.auditType]);

  return (
    <Row noGutters className="dashboard-header">
      <Col className="dashboard-header-col">
        <Row className="dashboard-header-col__row">
          <Col>
            <p>Audits</p>
          </Col>
        </Row>
      </Col>
      <Col xs="auto">
        <Row className="dashboard-header-col__row">
          <Col xs="auto">
            <DropdownButton title={outletTitle} size="sm">
              <DropdownItem
                value={0}
                active={props.filters.validated === 0}
                onSelect={() => props.setValidatedFilter(0)}
                onClick={() => setOutletTitle("All Outlets")}
              >
                All Outlets
              </DropdownItem>
              <DropdownItem
                value={true}
                active={props.filters.validated === true}
                onSelect={() => props.setValidatedFilter(true)}
                onClick={() => setOutletTitle("Validated Locations")}
              >
                Validated Locations
              </DropdownItem>
              <DropdownItem
                value={false}
                active={props.filters.validated === false}
                onSelect={() => props.setValidatedFilter(false)}
                onClick={() => setOutletTitle("Unvalidated Locations")}
              >
                Unvalidated Locations
              </DropdownItem>
            </DropdownButton>
          </Col>
          <Col xs="auto" className="datepicker-col">
            <DatePicker
              id="audits-datepicker"
              startDate={props.filters.startDate}
              endDate={props.filters.endDate}
              setDate={props.setDate}
            />
          </Col>
          <Col xs="auto">
            <DropdownButton title={regionTitle} size="sm">
              <DropdownItem
                active={props.filters.regionId === 0}
                onSelect={() => props.setRegionFilter(0)}
                onClick={() => setRegionTitle("All Regions")}
              >
                All Regions
              </DropdownItem>
              {props.regions.map((region) => (
                <DropdownItem
                  key={`regionFilter_${region.id}`}
                  onSelect={() => props.setRegionFilter(region.id)}
                  active={props.filters.regionId === region.id}
                  onClick={() =>
                    setRegionTitle(`${region.code} - ${region.name}`)
                  }
                >
                  {region.code} - {region.name}
                </DropdownItem>
              ))}
            </DropdownButton>
          </Col>
          <Col xs="auto">
            <DropdownButton title={auditTypeTitle} size="sm">
              <DropdownItem
                value="all"
                active={props.filters.auditType === ""}
                onSelect={() => props.setAuditTypeFilter("all")}
                onClick={() => setAuditTypeTitle("All Outlets")}
              >
                All Audit Types
              </DropdownItem>
              <DropdownItem
                value={true}
                active={props.filters.auditType === "Outboard Engines"}
                onSelect={() => props.setAuditTypeFilter("Outboard Engines")}
                onClick={() => setAuditTypeTitle("Marine")}
              >
                Marine
              </DropdownItem>
              <DropdownItem
                value={false}
                active={props.filters.auditType === "Commercial Industrial"}
                onSelect={() =>
                  props.setAuditTypeFilter("Commercial Industrial")
                }
                onClick={() => setAuditTypeTitle("Industrial")}
              >
                Industrial
              </DropdownItem>
              <DropdownItem
                value={false}
                active={props.filters.validated === "Lawn and Garden"}
                onSelect={() => props.setAuditTypeFilter("Lawn and Garden")}
                onClick={() => setAuditTypeTitle("L&G")}
              >
                L&G
              </DropdownItem>
            </DropdownButton>
          </Col>
          <Col xs="auto">
            <CSVLink
              filename={
                "Audits-" + moment().format("YYYY-MM-DD hh:mm") + ".csv"
              }
              style={{ backgroundColor: "rgba(0,0,0,0)" }}
              data={props.exportData}
              target="_blank"
            >
              <Button size="sm" className="export-btn">
                Export Data
              </Button>
            </CSVLink>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AuditHeader;
