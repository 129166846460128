import React, { Component } from "react";
import { Col, Row, Table, Button, Pagination } from "react-bootstrap";
import { connect } from "react-redux";
import Request from '../../utils/request';
import TitleRow from "../../shared/TitleRow";
import { withRouter } from "react-router-dom";
import UsersHeader from './UsersHeader';

const mapStateToProps = state => {
  return {
    pagination: state.pagination
  };
};

class UsersViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      exportData: [],
      currentPage: 0,
      numberPerPage: 10,
      totalPages: 0,
      filters: {
        regionId: 0,
        userRoleId: 0
      }
    };
  }

  getUsers = async() => {
    let users = await Request.get('users');

    const exportData = users.data.map((user) => {
        return {
            "First Name": user.first_name,
            "Last Name": user.last_name,
            "Email": user.email,
            "Region": user.area.region.name,
            "Area": user.area.name,
            "Role": user.role.title
        }
    });

    users = users.data.map(user => {
      return {
        id: user.id,
        firstName: user.first_name,
        surname: user.last_name,
        emailAddress: user.email,
        region: user.area.region,
        role: user.role
      };
    })
    this.setState({ users, exportData });
  };

  componentDidMount = async() => {
    await this.getUsers();
    const totalPages = Math.ceil(this.state.users.length / this.state.numberPerPage)
    this.setState({totalPages});
  };

  get listUsers() {
    const startPos = this.state.currentPage * this.state.numberPerPage;
    const endPos = startPos + this.state.numberPerPage;

    let users = this.state.users.slice(0);

    users = users.filter((user) => {
      if(this.state.filters.regionId > 0 && user.region.id !== this.state.filters.regionId) return null;

      if(this.state.filters.userRoleId > 0 && user.role.id !== this.state.filters.userRoleId) return null;

      return user;
    })

    users = users.slice(startPos, endPos);
    return users;
  }

  setRegionFilter = (regionId) => {
    const filters = {...this.state.filters};
    filters.regionId = regionId;
    this.setState({filters})
  }

  setUserRole = (userRoleId) => {
    const filters = {...this.state.filters};
    filters.userRoleId = userRoleId;
    this.setState({filters});
  }

  render() {
    return (
        <Col className="view-col">
          <UsersHeader
              exportData={this.state.exportData}
              filters={this.state.filters}
              setRegionFilter={this.setRegionFilter}
              setUserRole={this.setUserRole}
              onClick={() => this.props.history.push('/user')}
          />

          <Table responsive hover size="sm">
            <TitleRow view="users" />
            <tbody className="data-body">
            {
              this.listUsers.map((user) => {
                return (
                    <tr key={user.id}>
                      <td>{user.surname}</td>
                      <td>{user.firstName}</td>
                      <td>{user.emailAddress}</td>
                      <td>{user.region.name}</td>
                      <td>{user.role.title}</td>
                      <td>
                        <Button
                            className="action-btn view-btn"
                            onClick={() => this.props.history.push(`/user/${user.id}`)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                )
              })
            }
            </tbody>
          </Table>
          <Row noGutters>
            <Col>
              <Pagination>
                {
                  this.state.currentPage > 0 && <Pagination.First onClick={() => this.setState({currentPage: 0})}/>
                }
                {
                  Array.from(Array(this.state.totalPages), (e, i) => {
                    return (
                        <Pagination.Item
                            key={`paginationButton_${i}`}
                            onClick={() => this.setState({currentPage: i})}
                            active={ this.state.currentPage === i}
                        >{i + 1}</Pagination.Item>
                    )
                  })
                }
                {
                  this.state.totalPages > 0 &&
                  this.state.currentPage !== (this.state.totalPages -1) &&
                  <Pagination.Last onClick={() => this.setState({currentPage: this.state.totalPages - 1})}/>
                }
              </Pagination>
            </Col>
          </Row>
        </Col>
    );
  }
}

export default withRouter(connect(mapStateToProps)(UsersViewComponent));
