import React, {useState} from "react";
import Request from '../../utils/request';
import {CSVDownload, CSVLink} from "react-csv";

import {
    Col,
    Row,
    Button,
    DropdownButton,
    DropdownItem
  } from "react-bootstrap";
import moment from "moment";
  
const UsersHeader = props => {

    const [regions, setRegions] = useState([]);
    const [roles, setRoles] = useState([]);

    React.useEffect(() => {
      getRegions();
      getRoles();
    }, [])

    const getRegions = async () => {
      const response = await Request.get('regions');
      setRegions(response.data);
    }

    const getRoles = async () => {
      const response = await Request.get('roles');
      setRoles(response.data);
    }
    
    const getTitle = () => {
      const region = regions.find((region) => {
        if(region.id === props.filters.regionId) return region;
      });

      if(!region) return 'All Regions';
      return `${region.code} - ${region.name}`;
    }

    const getRoleTitle = () => {
      const role = roles.find((role) => {
        if(role.id === props.filters.userRoleId) return role;
      });

      if(!role) return 'User Types';
      return `${role.title}`;
    }

    return (
      <Row noGutters className="dashboard-header">
        <Col xs="auto" className="dashboard-header-col">
          <Row className="dashboard-header-col__row">
            <Col>
              <p>Users</p>
            </Col>
            <Col xs="auto">
              <Button
                onClick={props.onClick}
                size="sm"
                className="btn--primary"
              >
                Add New User
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <Row className="dashboard-header-col__row">
            <Col xs="auto">
              <DropdownButton title={getTitle()} size="sm">
                <DropdownItem
                  active={props.filters.regionId === 0}
                  onSelect={() => props.setRegionFilter(0)}
                >All Regions</DropdownItem>
                {
                    regions.map((region) => 
                      <DropdownItem 
                        key={`regionFilter_${region.id}`}
                        onSelect={() => props.setRegionFilter(region.id)}
                        active={props.filters.regionId === region.id}
                      >
                        {region.code} - {region.name}
                      </DropdownItem>
                    )
                }
              </DropdownButton>
            </Col>
            <Col xs="auto">
              <DropdownButton title={getRoleTitle()} size="sm">
                <DropdownItem
                  active={props.filters.roledId === 0}
                  onSelect={() => props.setUserRole(0)}
                >All User Types</DropdownItem>
                {
                    roles.map((role) => 
                      <DropdownItem 
                        key={`roleFilter_${role.id}`}
                        onSelect={() => props.setUserRole(role.id)}
                        active={props.filters.userRoleId === role.id}
                      >
                        {role.title}
                      </DropdownItem>
                    )
                }
              </DropdownButton>
            </Col>
            <Col xs="auto">
            <CSVLink filename={"Users-"+moment().format('YYYY-MM-DD hh:mm')+".csv"} style={{"backgroundColor": "rgba(0,0,0,0)"}} data={props.exportData} target="_blank" >
                <Button size="sm" className="export-btn">
                    Export Data
                </Button>
            </CSVLink>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  export default UsersHeader;