import React, { Component } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { FaFilePdf } from "react-icons/fa";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    state: state
  };
};

const SupportView = props => {
  return (
    <Col className="view-col">
      <Col>
        <Row noGutters className="dashboard-header">
          <Col className="black-title">Support</Col>
        </Row>
        <Row noGutters>
          <Col lg="6" className="support-inner">
            <Row noGutters>
              <Col className="support-col">
                <p>
                  <span className="support-header">Change Password</span>
                </p>
                <p>
                  <Button
                    variant="link"
                    className="click-here"
                    href="./change-password"
                  >
                    Click here
                  </Button>{" "}
                  to update password
                </p>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="support-col">
                <p>
                  <span className="support-header">User Guide</span>
                </p>
                <p>
                  <span className="pdf">
                    <a href="*">
                      <FaFilePdf />
                    </a>{" "}
                  </span>
                  Download User Guide
                </p>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="support-col">
                <p>
                  <span className="support-header">Support Queries</span>
                </p>
                <p>
                  For further assistance, email{" "}
                  <a href="mailto:PPDlrAuditApp@honda-eu.com">
                    PPDlrAuditApp@honda-eu.com
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

class SupportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.changePassword = this.changePassword.bind(this);
  }

  changePassword() {}

  render() {
    return <SupportView change={this.changePassword} />;
  }
}

export default connect(mapStateToProps)(SupportComponent);
