import React, { Component } from 'react';
import {Button, ButtonGroup, Col, Row, Table} from 'react-bootstrap';
import StatusRow from './StatusRow';
import WideRow from '../../shared/WideRow';
import DUMMY_DATA from '../../DUMMY_DATA';
import DeleteModal from '../../shared/DeleteModal';
import Request from '../../utils/request';
import TitleRow from "../../shared/TitleRow";
import {FaExclamationCircle} from "react-icons/all";
import { withRouter } from "react-router-dom";
import moment from 'moment';

const auditStatus = DUMMY_DATA.audited_this_year;
const MCFS = DUMMY_DATA.most_commonly_failed;
const auditsData = DUMMY_DATA.audits;

const statusData = {
    auditStatus,
    MCFS
}

const DashboardView = (props) => {
    return (
        <Col className="view-col">
            <StatusRow data={props.statusData} />

            {/* AUDITS THAT NEED LOCATION VALIDATION */}
            <Col className="status-col dashboard-row">
                <Row noGutters className="dashboard-header">
                    <Col xs="auto" className="dashboard-header-col">
                        <p>Audits Requiring Location Validation</p>
                    </Col>
                    <Col>
                        <Button
                            className="btn--primary btn btn-primary btn-sm"
                            onClick={() => props.redirect('audits')}
                        >
                            View All
                        </Button>
                    </Col>
                </Row>
                <Table responsive hover size="sm">
                <TitleRow view="audits" />
                <tbody className="data-body">
                {(props.needsValidating.length !== 0) ?
                    props.needsValidating.map((audit) => {
                        return (
                            <tr key={audit.id}>
                                <td>
                                    {!audit.approved ? <FaExclamationCircle className="flag" /> : null}
                                </td>
                                <td>{audit.name}</td>
                                <td>{audit.address_1}</td>
                                <td>
                                    {
                                        props.regions.map((reg) => {
                                            if(reg.id === audit.areas_regions_id)
                                            {
                                                return reg.code;
                                            }
                                        })
                                    }
                                </td>
                                <td>
                                    {moment(audit.audits[0].timestamp).format('YYYY-MM-DD')}
                                </td>
                                <td>
                                    {(audit.pass_data.outboard_engines.total) ?
                                        `${audit.pass_data.outboard_engines.passed}/${audit.pass_data.outboard_engines.total}` :
                                        'N/A'
                                    }
                                </td>
                                <td>
                                    {(audit.pass_data.commercial_industrial.total) ?
                                        `${audit.pass_data.commercial_industrial.passed}/${audit.pass_data.commercial_industrial.total}` :
                                        'N/A'
                                    }
                                </td>
                                <td>
                                    {(audit.pass_data.lawn_and_garden.total) ?
                                        `${audit.pass_data.lawn_and_garden.passed}/${audit.pass_data.lawn_and_garden.total}` :
                                        'N/A'
                                    }
                                </td>
                                <td>
                                    <ButtonGroup className="triple">
                                        <Button
                                            className="action-btn"
                                            onClick={() => props.redirect(`/audit/${audit.id}`)}
                                        >
                                            View
                                        </Button>
                                        <Button className="action-btn" onClick={() => props.handleValidate(audit.id)}>
                                            Validate
                                        </Button>
                                        <Button className="action-btn" onClick={() => props.handleShow(audit.id)} >
                                            Delete
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        )
                    }
                    ) :
                    <tr>
                        <td colSpan="9" className="text-center">
                            <p>No audits currently require location validation</p>
                        </td>
                    </tr>
                }
                </tbody>
            </Table>
            </Col>

            {/* ALL AUDITS */}
            <Col className="status-col dashboard-row">
                <Row noGutters className="dashboard-header">
                    <Col xs="auto" className="dashboard-header-col">
                        <p>All Audits</p>
                    </Col>
                    <Col>
                        <Button
                            className="btn--primary btn btn-primary btn-sm"
                            onClick={() => props.redirect('audits')}
                        >
                            View All
                        </Button>
                    </Col>
                </Row>
                <Table responsive hover size="sm">
                    <TitleRow view="audits" />
                    <tbody className="data-body">
                    {(props.audits.length !== 0) ?
                        props.audits.map((audit) => {
                                return (
                                    <tr key={audit.id}>
                                        <td>
                                            {!audit.approved ? <FaExclamationCircle className="flag" /> : null}
                                        </td>
                                        <td>{audit.name}</td>
                                        <td>{audit.address_1}</td>
                                        <td>
                                            {
                                                props.regions.map((reg) => {
                                                    if(reg.id === audit.areas_regions_id)
                                                    {
                                                        return reg.code;
                                                    }
                                                })
                                            }
                                        </td>
                                        <td>{moment(audit.audits[0].timestamp).format('YYYY-MM-DD')}</td>
                                        <td>
                                            {(audit.pass_data.outboard_engines.total) ?
                                                `${audit.pass_data.outboard_engines.passed}/${audit.pass_data.outboard_engines.total}` :
                                                'N/A'
                                            }
                                        </td>
                                        <td>
                                            {(audit.pass_data.commercial_industrial.total) ?
                                                `${audit.pass_data.commercial_industrial.passed}/${audit.pass_data.commercial_industrial.total}` :
                                                'N/A'
                                            }
                                        </td>
                                        <td>
                                            {(audit.pass_data.lawn_and_garden.total) ?
                                                `${audit.pass_data.lawn_and_garden.passed}/${audit.pass_data.lawn_and_garden.total}` :
                                                'N/A'
                                            }
                                        </td>
                                        <td>
                                            <ButtonGroup className="triple">
                                                <Button
                                                    className="action-btn"
                                                    onClick={() => props.redirect(`/audit/${audit.id}`)}
                                                >
                                                    View
                                                </Button>
                                                <Button className="action-btn" onClick={() => props.redirectThenPrint(audit.id)}>
                                                    Print
                                                </Button>
                                                <Button className="action-btn" onClick={() => props.handleShow(audit.id)}>
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                )
                            }
                        ) :
                        <tr>
                            <td colSpan="9" className="text-center">
                                <p>No audits currently require location validation</p>
                            </td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </Col>

            {/* DELETE MODAL */}
            <DeleteModal show={props.show} handleClose={props.handleClose} handleDelete={props.handleDelete} />
        </Col>
    );
};

class DashboardViewComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audits: [],
            regions: [],
            audit_data: {
                audits: {
                    auditedPercentage: '',
                    thisYearSoFar: 0,
                    notYetAudited: 0,
                },
                questions: {
                    lawn_and_garden: [],
                    commercial_industrial: [],
                    outboard_engines: [],
                }
            },
            show: false,
            showAll: {
                valid: false,
                normal: false
            },
            idToDelete: 0,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleShowAll = this.handleShowAll.bind(this);
    }

    componentDidMount = async() => {
        const audits = await this.getAudits();
        const audit_data = await this.getDashboard();
        const regions = await this.getRegions();

        this.setState({
            audits: audits.data,
            regions: regions.data,
            audit_data: audit_data.data
        });
    }

    getAudits = async() => {
        return await Request.get('audits');
    }

    getDashboard = async() => {
        return await Request.get('dashboard');
    }

    getRegions = async() => {
        return await Request.get('regions');
    }


    get auditsNeedValidating() {
        const audits = [...this.state.audits];

        const filteredAudits = audits.filter((audit) => {
            return (!audit.approved) ? audit : null;
        })

        return filteredAudits.slice(0, 5);
    }

    get lastFiveAudits() {
        const audits = [...this.state.audits];
        return audits.slice(0, 5);
    }

    redirectTo = (url) => {
        this.props.history.push(url);
    }

    handleShowAll (type) {
        if (type === 'valid') {
            this.setState(prevState => ({
                showAll: {
                    ...prevState.showAll,
                    valid: true
                }
            }))
        } else {
            this.setState(prevState => ({
                showAll: {
                    ...prevState.showAll,
                    normal: true
                }
            }))
        }
    }

    handleShow (id) {
        this.setState({
            show: true,
            idToDelete: id
        });
    }

    handleClose () {
        this.setState({
            show: false,
        });
    }

    deleteItem = async() => {
        await Request.del(`audits/${this.state.idToDelete}`).then( async(response) => {
            if(response.status === 'success')
            {
                alert('Audit Deleted!');
                this.handleClose();
                const audits = await Request.get('audits');
                const audit_data = await Request.get('dashboard');

                this.setState({
                    audits: audits.data,
                    audit_data: audit_data.data
                });
            }
        })
    }

    validateLocation = async(id) => {
        let location = await Request.get(`locations/${id}`);
        location = location.data[0];
        location.approved = true;

        await Request.put(`locations/${id}`, location).then(async(response) => {
            if(response.status === "success")
            {
                const audits = await this.getAudits();
                const audit_data = await this.getDashboard();
                const regions = await this.getRegions();

                this.setState({
                    audits: audits.data,
                    regions: regions.data,
                    audit_data: audit_data.data
                });

                alert('Successfully validated location');
            }
        })
    }

    redirectThenPrint = (id) => {
        this.props.history.push(`/audit/${id}?print=true`)
    }

    render () {
        return (
            <DashboardView
                handleValidate={(id) =>this.validateLocation(id)}
                redirectThenPrint={(id) =>this.redirectThenPrint(id)}
                regions={this.state.regions}
                needsValidating={this.auditsNeedValidating}
                audits={this.lastFiveAudits}
                redirect={(url) => {this.redirectTo(url)}}
                statusData={this.state.audit_data}

                showAll={this.state.showAll}
                handleShowAll={this.handleShowAll}
                show={this.state.show}
                handleClose={this.handleClose}
                handleShow={this.handleShow}
                handleDelete={this.deleteItem}
            />
        )
    }
}

export default withRouter(DashboardViewComponent);


