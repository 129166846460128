import React, { Component } from "react";
import { connect } from "react-redux";
import { auditData } from "../actions";
import { withRouter } from "react-router-dom";
import { Table, Col } from "react-bootstrap";
import DashboardHeader from "./DashboardHeader";
import TitleRow from "./TitleRow";
import { TripleButton, ViewButton } from "./buttons";
import { FaExclamationCircle } from "react-icons/fa";
import "./table.css";

const Default = props => {
  return (
    <>
      <td>
        {props.data.flag ? <FaExclamationCircle className="flag" /> : null}
      </td>
      <td>{props.data.locationName}</td>
      <td>{props.data.address}</td>
      <td>{props.data.region}</td>
      <td>{props.data.auditDate}</td>
      <td>{props.data.marine}</td>
      <td>{props.data.industrial}</td>
      <td>{props.data.lawn}</td>
      <td>
        <TripleButton
          data={props.data}
          handleShow={props.handleShow}
          onClick={props.changeView}
        />
      </td>
    </>
  );
};

const mapStateToProps = state => {
  return {
    state: state
  };
};

class WideRowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.changeTheView = this.changeTheView.bind(this);
  }

  changeTheView(props, data) {
    this.props.history.push(`${props}${data && data.id ? "/" + data.id : ""}`);

    if (data) {
      this.props.dispatch(auditData(data));
    }
  }

  render() {
    const returnList = () => {
      let list = [];

      let data = this.props.data;

      if (this.props.dashboard) {
        if (this.props.needValid) {
          data = this.props.data.filter(el => {
            return el.flag === true;
          });
        }
        if (data.length > 5 && !this.props.showAll) {
          list = data.slice(0, 5);
        } else {
          list = data;
        }
      } else {
        const number = this.props.page - 1;
        list = data[number] ? data[number] : [];
      }

      const map = list.map((item, index) => {
        return (
          <tr key={index}>
            {this.props.view === "users" ? (
              // <Users data={item} changeView={this.changeTheView} />
              <div>Users</div>
            ) : this.props.view === "outlets" ? (
              {
                /* <Outlets
                handleShow={this.props.handleShow}
                view={this.props.view}
                data={item}
                changeView={this.changeTheView}
              /> */
              }
            ) : (
              <Default
                handleShow={this.props.handleShow}
                view={this.props.view}
                data={item}
                changeView={this.changeTheView}
              />
            )}
          </tr>
        );
      });
      return map;
    };

    return (
      <Col className="status-col dashboard-row">
        <DashboardHeader
          view={this.props.view}
          changeView={this.changeTheView}
          text={this.props.header}
          dropdowns={this.props.withDropdowns}
          type={this.props.type}
          handleShowAll={this.props.handleShowAll}
          viewAll={this.props.viewAll}
        />
        <Table responsive hover size="sm">
          <TitleRow view={this.props.view} />
          <tbody className="data-body">
            {returnList().length !== 0 ? (
              returnList()
            ) : (
              <p>{this.props.emptyMsg}</p>
            )}
          </tbody>
        </Table>
      </Col>
    );
  }
}

export default withRouter(connect(mapStateToProps)(WideRowComponent));
