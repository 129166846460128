import React, { Component } from "react";
import {
  Button,
  Col,
  Row,
  Table,
  Pagination,
  ButtonGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import AuditsHeader from "../Audits/AuditsHeader";
import Request from "../../utils/request";
import TitleRow from "../../shared/TitleRow";
import { withRouter } from "react-router-dom";
import DeleteModal from "../../shared/DeleteModal";
import { FaExclamationCircle } from "react-icons/all";
import moment from "moment";

const mapStateToProps = (state) => {
  return {
    pagination: state.pagination,
  };
};

class AuditsViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      show: false,
      audits: [],
      exportData: [],
      currentPage: 0,
      numberPerPage: 10,
      totalPages: 0,
      currentId: 0,
      filters: {
        regionId: 0,
        userRoleId: 0,
        validated: 0,
        auditType: "all",
        startDate: null,
        endDate: null,
      },
    };
  }

  getAudits = async () => {
    let audits = await Request.get("audits");

    const exportData = audits.data.map((audit) => {
      return {
        "Outlet Name": audit.name,
        Approved: audit.approved ? "✅" : "❌",
        Date: moment(audit.audits[0].timestamp).format("YYYY-MM-DD"),
        Address: audit.address_1,
        Town: audit.town,
        County: audit.county,
        Marine: `Passed: ${audit.pass_data.outboard_engines.passed} Failed: ${audit.pass_data.outboard_engines.failed} Total: ${audit.pass_data.outboard_engines.total}`,
        Industrial: `Passed: ${audit.pass_data.commercial_industrial.passed} Failed: ${audit.pass_data.commercial_industrial.failed} Total: ${audit.pass_data.commercial_industrial.total}`,
        Lawn: `Passed: ${audit.pass_data.lawn_and_garden.passed} Failed: ${audit.pass_data.lawn_and_garden.failed} Total: ${audit.pass_data.lawn_and_garden.total}`,
      };
    });

    audits = audits.data.map((audit) => {
      return {
        id: audit.id,
        name: audit.name,
        address_1: audit.address_1,
        town: audit.town,
        county: audit.county,
        post_code: audit.post_code,
        approved: audit.approved,
        areas_id: audit.areas_id,
        areas_regions_id: audit.areas_regions_id,
        pass_data: audit.pass_data,
        audits: audit.audits,
      };
    });

    this.setState({
      audits,
      exportData,
    });
  };

  getRegions = async () => {
    const response = await Request.get("regions");
    this.setState({
      regions: response.data,
    });
  };

  componentDidMount = async () => {
    await this.getAudits();
    await this.getRegions();
    await this.getAudits();
    const totalPages = Math.ceil(
      this.state.audits.length / this.state.numberPerPage
    );

    let savedFilters = localStorage.getItem("auditFilters");
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);
      this.setState({
        filters: {
          ...savedFilters,
          startDate: savedFilters.startDate
            ? moment(savedFilters.startDate)
            : null,
          endDate: savedFilters.endDate ? moment(savedFilters.endDate) : null,
        },
      });
    }
    this.setState({ totalPages });
  };

  get listAudits() {
    const startPos = this.state.currentPage * this.state.numberPerPage;
    const endPos = startPos + this.state.numberPerPage;

    let audits = this.state.audits.slice(0);

    audits = audits.filter((audit) => {
      if (
        this.state.filters.validated !== 0 &&
        audit.approved !== this.state.filters.validated
      )
        return null;
      if (
        this.state.filters.regionId > 0 &&
        audit.areas_regions_id !== this.state.filters.regionId
      )
        return null;
      if (
        this.state.filters.userRoleId > 0 &&
        audit.role.id !== this.state.filters.userRoleId
      )
        return null;

      if (this.state.filters.auditType !== "all") {
        switch (this.state.filters.auditType) {
          case "Outboard Engines":
            if (Object.keys(audit.pass_data.outboard_engines).length === 0)
              return null;
            break;
          case "Lawn and Garden":
            if (Object.keys(audit.pass_data.lawn_and_garden).length === 0)
              return null;
            break;
          case "Commercial Industrial":
            if (Object.keys(audit.pass_data.commercial_industrial).length === 0)
              return null;
            break;
          default:
        }
      }

      if (
        this.state.filters.startDate !== null ||
        this.state.filters.endDate !== null
      ) {
        const allow = audit.audits.some((a) => {
          if (this.state.filters.startDate !== null) {
            const startDate = moment(a.timestamp);
            if (startDate.isBefore(this.state.filters.startDate, "day"))
              return false;
          }

          if (this.state.filters.endDate !== null) {
            const endDate = moment(a.timestamp);
            if (endDate.isAfter(this.state.filters.endDate, "day"))
              return false;
          }
          return true;
        });

        if (!allow) return null;
      }
      return audit;
    });

    audits = audits.slice(startPos, endPos);
    return audits;
  }

  handleShow(id) {
    this.setState({
      show: true,
      currentId: id,
    });
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }

  saveFilterToLocalStorage = (filters) => {
    console.log(JSON.stringify(filters));
    localStorage.setItem("auditFilters", JSON.stringify(filters));
  };

  setValidatedFilter = (filter) => {
    const filters = { ...this.state.filters };
    filters.validated = filter;
    this.saveFilterToLocalStorage(filters);
    this.setState({ filters });
  };

  setRegionFilter = (regionId) => {
    const filters = { ...this.state.filters };
    filters.regionId = regionId;
    this.saveFilterToLocalStorage(filters);
    this.setState({ filters });
  };

  setAuditTypeFilter = (auditType) => {
    const filters = { ...this.state.filters };
    filters.auditType = auditType;
    this.saveFilterToLocalStorage(filters);
    this.setState({ filters });
  };

  setDateFilter = (startDate, endDate) => {
    const filters = { ...this.state.filters };
    filters.startDate = startDate;
    filters.endDate = endDate;
    this.saveFilterToLocalStorage(filters);
    console.log(filters);
    this.setState({ filters });
  };

  handleDeleteAudit = async () => {
    await Request.del(`audits/${this.state.currentId}`).then((response) => {
      if (response.status === "success") {
        this.getAudits();
        alert("Audit Deleted!");
        this.handleClose();
      }
    });
  };

  redirectThenPrint = (id) => {
    this.props.history.push(`/audit/${id}?print=true`);
  };

  render() {
    return (
      <Col className="view-col">
        <AuditsHeader
          exportData={this.state.exportData}
          filters={this.state.filters}
          regions={this.state.regions}
          setValidatedFilter={(filter) => this.setValidatedFilter(filter)}
          setRegionFilter={(filter) => this.setRegionFilter(filter)}
          setAuditTypeFilter={(filter) => this.setAuditTypeFilter(filter)}
          setDate={this.setDateFilter}
        />
        <Table responsive hover size="sm">
          <TitleRow view="audits" />
          <tbody className="data-body">
            {this.listAudits.map((audit) => {
              return (
                <tr key={audit.id}>
                  <td>
                    {!audit.approved ? (
                      <FaExclamationCircle className="flag" />
                    ) : null}
                  </td>
                  <td>{audit.name}</td>
                  <td>{audit.address_1}</td>
                  <td>
                    {this.state.regions.map((reg) => {
                      if (reg.id === audit.areas_regions_id) {
                        return reg.code;
                      }
                    })}
                  </td>
                  <td>
                    {moment(audit.audits[0].timestamp).format("YYYY-MM-DD")}
                  </td>
                  <td>
                    {audit.pass_data.outboard_engines.total
                      ? `${audit.pass_data.outboard_engines.passed}/${audit.pass_data.outboard_engines.total}`
                      : "N/A"}
                  </td>
                  <td>
                    {audit.pass_data.commercial_industrial.total
                      ? `${audit.pass_data.commercial_industrial.passed}/${audit.pass_data.commercial_industrial.total}`
                      : "N/A"}
                  </td>
                  <td>
                    {audit.pass_data.lawn_and_garden.total
                      ? `${audit.pass_data.lawn_and_garden.passed}/${audit.pass_data.lawn_and_garden.total}`
                      : "N/A"}
                  </td>
                  <td>
                    <ButtonGroup className="triple">
                      <Button
                        className="action-btn"
                        onClick={() =>
                          this.props.history.push(`/audit/${audit.id}`)
                        }
                      >
                        View
                      </Button>
                      <Button
                        onClick={() => this.redirectThenPrint(audit.id)}
                        className="action-btn"
                      >
                        Print
                      </Button>
                      <Button
                        className="action-btn"
                        onClick={() => this.handleShow(audit.id)}
                      >
                        Delete
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Row noGutters>
          <Col>
            <Pagination>
              {this.state.currentPage > 0 && (
                <Pagination.First
                  onClick={() => this.setState({ currentPage: 0 })}
                />
              )}
              {Array.from(Array(this.state.totalPages), (e, i) => {
                return (
                  <Pagination.Item
                    key={`paginationButton_${i}`}
                    onClick={() => this.setState({ currentPage: i })}
                    active={this.state.currentPage === i}
                  >
                    {i + 1}
                  </Pagination.Item>
                );
              })}
              {this.state.totalPages > 0 &&
                this.state.currentPage !== this.state.totalPages - 1 && (
                  <Pagination.Last
                    onClick={() =>
                      this.setState({ currentPage: this.state.totalPages - 1 })
                    }
                  />
                )}
            </Pagination>
          </Col>
        </Row>
        <DeleteModal
          show={this.state.show}
          handleClose={() => this.handleClose()}
          handleDelete={() => this.handleDeleteAudit()}
        />
      </Col>
    );
  }
}

export default withRouter(connect(mapStateToProps)(AuditsViewComponent));
