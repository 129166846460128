import React from "react";
import Request from "../utils/request";
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import { withRouter } from "react-router";

const handleLogin = async(e, props) => {
  e.preventDefault();

  const email = document.getElementById('formEmail').value;
  const password = document.getElementById('formPassword').value;

  const response = await Request.post('auth/login', {email, password});

  if(response.status === 'success')
  {
    localStorage.setItem('token', response.data.token);
    props.history.push('/dashboard');
  } else {
    alert('There was an error logging in! Please check your username and password and try again.');
  }
};

const LoginForm = props => {
  return (
    <div className="Login-form-container">
      <img
        className="Login-form-container__logo"
        src={require("../resources/images/honda-logo-white.png")}
        alt=""
      />
      <Form>
        <FormGroup controlId="formEmail">
          <FormLabel className="login-label">Email address</FormLabel>
          <FormControl name="email" type="email" placeholder="Email address" />
        </FormGroup>
        <FormGroup controlId="formPassword">
          <FormLabel className="login-label">Password</FormLabel>
          <FormControl name="password" type="password" placeholder="Password" />
        </FormGroup>
        <Row>
          <Col>
            <Button
              variant="link"
              size="sm"
              className="button-forgot"
              href="./change-password"
            >
              Forgotten Password
            </Button>
          </Col>
          <Col>
            <Button
              href="/dashboard"
              variant="primary"
              size="sm"
              type="button"
              onClick={(e) => handleLogin(e, props)}
              className="float-right w-75"
            >
              Log In
            </Button>
          </Col>
        </Row>
        <Row noGutters className="helptext">
          ** click login to navigate to dashboard
        </Row>
      </Form>
    </div>
  );
};

export default withRouter(LoginForm);
