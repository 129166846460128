import React, { Component } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./DatePicker.css";
import moment from 'moment'

class DatepickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <DateRangePicker
        small
        isOutsideRange={() => false}
        startDate={this.props.startDate} // momentPropTypes.momentObj or null,
        startDateId={`startDate_${this.props.id}`} // PropTypes.string.isRequired,
        endDate={this.props.endDate} // momentPropTypes.momentObj or null,
        endDateId={`endDate_${this.props.id}`} // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) =>{
            this.props.setDate( startDate, endDate )
          }
        }
        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
        showDefaultInputIcon={true}
        displayFormat={ () => 'DD/MM/YYYY'}
        monthFormat="MMM YYYY"
      />
    );
  }
}

export default DatepickerComponent;
