import React from 'react';
import './Login.css';
import { Container } from 'react-bootstrap';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';

const Login = (props) => {
    return (
        <Container className='Login-container'>
            { props.forgot ? <ForgotPassword /> : <LoginForm forgotPassword={props.toggleForgot} /> }
        </Container>
    );
};

export default Login;