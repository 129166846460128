import React, { Component } from "react";
import { connect } from "react-redux";
import Request from "../../utils/request";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  FormCheck
} from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import {
  DeleteLocationButton,
  SaveChangesButton,
  AddLocationButton
} from "../../shared/buttons";
import DeleteModal from "../../shared/DeleteModal";

const mapStateToProps = state => {
  return {
    state: state
  };
};

const LocationForm = props => {
  return (
    <Col>
      <Row noGutters className="dashboard-header">
        <Col>
          <Row noGutters>
            <p className="black-title">Outlet Locations</p>
            <span className="angle-right">
              <FaAngleRight />
            </span>
            <p className="red-title">Location</p>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col lg="6" className="edit-container">
          <Form>
            <FormGroup controlId="formGroupname">
              <FormLabel>Outlet Name</FormLabel>
              <FormControl
                type="text"
                placeholder="Outlet Name"
                onChange={e => props.handleUpdateInput(e, "name")}
                value={props.location.name}
              ></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupAddress_1">
              <FormLabel>Address 1</FormLabel>
              <FormControl
                type="text"
                placeholder="Address 1"
                onChange={e => props.handleUpdateInput(e, "address_1")}
                value={props.location.address_1}
              ></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupTown">
              <FormLabel>Town</FormLabel>
              <FormControl
                type="text"
                placeholder="Town"
                onChange={e => props.handleUpdateInput(e, "town")}
                value={props.location.town}
              ></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupCounty">
              <FormLabel>County</FormLabel>
              <FormControl
                type="text"
                placeholder="County"
                onChange={e => props.handleUpdateInput(e, "county")}
                value={props.location.county}
              ></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupPost_Code">
              <FormLabel>Post Code</FormLabel>
              <FormControl
                type="text"
                placeholder="Post Code"
                onChange={e => props.handleUpdateInput(e, "post_code")}
                value={props.location.post_code}
              ></FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupRegion">
              <FormLabel>Region</FormLabel>
              <FormControl
                as="select"
                onChange={e => props.handleUpdateInput(e, "region")}
                value={props.selectedRegion.id}
              >
                <option key={`locationRegionDefault`} value={0}>
                  Choose a region...
                </option>
                {props.regions.map((option, index) => {
                  return (
                    <option key={`locationRegion${index}`} value={option.id}>
                      {option.code}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup controlId="formGroupArea">
              <FormLabel>Area</FormLabel>
              <FormControl
                as="select"
                onChange={e => props.handleUpdateInput(e, "area")}
                value={props.location.area.id}
              >
                <option key={`locationAreaDefault`} value={0}>
                  Choose an area...
                </option>
                {props.selectedRegion.areas.map((area, index) => {
                  return (
                    <option key={`locationRegion${index}`} value={area.id}>
                      {area.name}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
          </Form>

          <Form className="validation-loc">
            <FormLabel>Validate Location</FormLabel>
            <FormCheck
              type="checkbox"
              checked={props.location.approved}
              onChange={e => props.handleUpdateInput(e, "approved")}
              label="I confirm that the above details are correct for this location"
            />
          </Form>
          <Row noGutters className="bottom-buttons">
            <Col xs="6">
              {props.isLocation ? <DeleteLocationButton onClick={props.handleDelete} {...props}  /> : null}
            </Col>
            <Col xs="6" className="save-change">
              {props.isLocation ? (
                <SaveChangesButton onClick={props.handleSubmit} {...props} />
              ) : (
                <AddLocationButton onClick={props.handleSubmit} {...props} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {
        name: "",
        address_1: "",
        town: "",
        county: "",
        post_code: "",
        area: {
          id: 0,
          name: "",
          region: {
            id: 0,
            name: "",
            code: ""
          }
        },
        approved: false
      },
      isLocation: false,
      regions: [
        {
          name: "",
          code: "",
          id: 0,
          areas: [
            {
              id: 0,
              name: "",
              regions_id: 0
            }
          ]
        }
      ],
      areas: [
        {
          id: 0,
          name: "",
          regions_id: 0
        }
      ],
      selectedRegion: {
        name: "",
        code: "",
        id: 0,
        areas: [
          {
            id: 0,
            name: "",
            regions_id: 0
          }
        ]
      },
      deleteModal: {
        show: false
      }
    };
  }

  getLocation = async url => {
    const currentLocation = await Request.get(url);
    return currentLocation.data;
  };

  getSelectedRegion = (id, regions) => {
    return regions.find(region => id === region.id);
  };

  /* Hit the endpoint using the location id to return data for current location */
  componentDidMount = async() => {
    let paramId = this.props.match.params.id;
    /* Set initial location state */
    let currentLocation;

    if (paramId) {
      currentLocation = await this.getLocation(
        `locations/${parseInt(paramId)}`
      );
      if (currentLocation.length) {
        currentLocation = currentLocation[0];
      } else {
        this.props.history.goBack();
        /*  404 maybe?  */
      }
    } else {
      currentLocation = { ...this.state.location };
    }

    let regions = await Request.get("regions");
    regions = regions.data;

    let selectedRegion;

    if (currentLocation.area.region.id > 0) {
      selectedRegion = this.getSelectedRegion(
        currentLocation.area.region.id,
        regions
      );
    } else {
      selectedRegion = { ...this.state.regions[0] };
    }

    this.setState({
      regions: regions,
      isLocation: paramId ? true : false,
      location: currentLocation,
      selectedRegion
    });
  }

  getAreas = region => {
    const areas = region.areas.map(area => area);
    return areas;
  };

  handleUpdateInput = (e, type) => {
    let location = { ...this.state.location };
    let selectedRegion = { ...this.state.selectedRegion };
    let regions = this.state.regions.slice();

    switch (type) {
      case "name":
        location.name = e.target.value;
        break;
      case "address_1":
        location.address_1 = e.target.value;
        break;
      case "town":
        location.town = e.target.value;
        break;
      case "county":
        location.county = e.target.value;
        break;
      case "post_code":
        location.post_code = e.target.value;
        break;
      case "region":
        selectedRegion = regions.find(
          region => region.id === parseInt(e.target.value)
        );
        break;
      case "area":
        const region = regions.find(region => region.id === selectedRegion.id);
        const area = region.areas.find(
          area => area.id === parseInt(e.target.value)
        );
        location.area = {id: area.id, name: area.name};
        location.area.region = {id:selectedRegion.id, name:selectedRegion.name, code:selectedRegion.code};
        break;
      case "approved":
        location.approved = e.target.checked;
        break;
      default:
        break;
    }
    this.setState({ location, selectedRegion });
  };

  handleSubmit = async () => {
    let location = { ...this.state.location };
    try {
      if (this.state.isLocation) {
        await Request.put(`locations/${location.id}`, location).then((response) => {
            if(response.status === 'success'){
              alert('Updated Location!');
            }
        });
      } else {
        delete location.id;
        delete location.area.region.areas;
        location.areas_id = location.area.region.id;
        location.areas_regions_id = location.area.id;
        await Request.post(`locations/`, location).then((response) => {
            if(response.status === 'success'){
              this.setState({
                location: {
                  name: "",
                  address_1: "",
                  town: "",
                  county: "",
                  post_code: "",
                  area: {
                    id: 0,
                    name: "",
                    region: {
                      id: 0,
                      name: "",
                      code: ""
                    }
                  },
                  approved: false
                },
                selectedRegion: {
                  name: "",
                  code: "",
                  id: 0,
                  areas: [
                    {
                      id: 0,
                      name: "",
                      regions_id: 0
                    }
                  ]
                },
              });
              alert('Created Location!');
            }
        });
      }
    } catch (error) {
      /*  show a toast */
    }
  };

  handleDeleteLocation = async () => {
    await Request.del(`locations/${this.state.location.id}`).then((response) => {
      if(response.status === 'success')
      {
        alert('Deleted Location!');
        this.props.history.push('/outlet-locations');
      }
    })
  }

  openDeleteModal = () => {
    this.setState({
      deleteModal: {
        show: true
      }
    })
  }
  closeDeleteModal = () => {
    this.setState({
      deleteModal: {
        show: false
      }
    })
  }

  render() {
    return (
      <Col className="view-col">
        <LocationForm
          location={this.state.location}
          regions={this.state.regions}
          selectedRegion={this.state.selectedRegion}
          isLocation={this.state.isLocation}

          handleUpdateInput={this.handleUpdateInput}
          handleSubmit={this.handleSubmit}
          handleRegionChange={this.handleRegionChange}
          handleDelete={() => this.openDeleteModal()}
        />
        <DeleteModal
            show={this.state.deleteModal.show}
            handleClose={() => { this.closeDeleteModal() }}
            handleDelete={() => { this.handleDeleteLocation() }}
        />
      </Col>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Location));
