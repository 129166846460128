const audited_this_year = {
  percentage: 0.65,
  pass: true,
  num_audited: "xxx",
  num_to_be_audited: "xxx"
};

const most_commonly_failed = {
  marine: [
    [1.1, "Question title", 0.32],
    [1.1, "Thus is a much longer question length", 0.23],
    [1.1, "Thus is a much longer question length", 0.19],
    [1.1, "Question title mid length", 0.08],
    [1.1, "Thus is a much longer question length", 0.01]
  ],
  industrial: [
    [1.1, "Question title", 0.32],
    [1.1, "Thus is a much longer question length", 0.23],
    [1.1, "Question title", 0.19],
    [1.1, "Question title mid length", 0.08]
  ],
  lawnGarden: []
};

const audits = [
  {
    id: 1,
    locationName: "Location Name 1",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    id: 2,
    locationName: "Location Name 2",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: true,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: true,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: true,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: true,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: true,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: false,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  },
  {
    locationName: "Location Name",
    address: "Lorem Ipsum Rd.",
    region: "GB",
    auditDate: "2019-04-02",
    marine: "10/14",
    industrial: "14/14",
    lawn: "N/A",
    flag: true,
    town: "Town",
    county: "County",
    postcode: "Postcode"
  }
];

const locations = [
  {
    id: 1,
    validated: false,
    name: "Outlet Name 1",
    address_1: "Address",
    town: "Town",
    county: "dorset",
    post_code: "dt21te",
    region: {
      id: 1,
      name: "Great Britain",
      code: "GB",
      area: {
        id: 1
      }
    }
  },
  {
    id: 2,
    validated: false,
    name: "Outlet Name 2",
    address_1: "Address",
    town: "Town",
    county: "dorset",
    post_code: "dt21te",
    region: {
      id: 2,
      name: "Great Britain",
      code: "FR",
      area: {
        id: 1
      }
    }
  },
  {
    id: 3,
    validated: false,
    name: "Outlet Name 3",
    address_1: "Address",
    town: "Town",
    county: "dorset",
    post_code: "dt21te",
    region: {
      id: 3,
      name: "Great Britain",
      code: "IT",
      area: {
        id: 1
      }
    }
  },
  {
    id: 4,
    validated: false,
    name: "Outlet Name 4",
    address_1: "Address",
    town: "Town",
    county: "dorset",
    post_code: "dt21te",
    region: {
      id: 1,
      name: "Great Britain",
      code: "US",
      area: {
        id: 1
      }
    }
  }
];

const userMaker = (amount) => {
    let users = [];

    for (let i = 0; i < amount; i++)
    {
        users.push( {
            surname: "Grindlewald",
            firstName: "Gellert",
            emailAddress: "Email Address",
            region: "DE",
            userType: "Auditor"
        })
    }
    return users;
};

const users = userMaker(500);

const regions = [
  {
    id: 1,
    name: "Great Britain",
    code: "GB",
    area: [
      {
        id: 1,
        name: "North"
      },
      {
        id: 2,
        name: "South"
      }
    ]
  },
  {
    id: 2,
    name: "France",
    code: "FR",
    area: [
      {
        id: 3,
        name: "East"
      },
      {
        id: 4,
        name: "West"
      }
    ]
  }
];

const DUMMY_DATA = {
  audited_this_year,
  most_commonly_failed,
  audits,
  users,
  locations,
  regions
};

export default DUMMY_DATA;
