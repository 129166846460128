import React from "react";
import {
  Col,
  Row,
  Button,
  DropdownButton,
  DropdownItem
} from "react-bootstrap";
import DatePicker from "../utils/Datepicker";
import { useHistory } from "react-router-dom";
import {CSVLink} from "react-csv";
import moment from "moment";

const HeaderButton = props => {
  const history = useHistory();

  return (
    <Button
      onClick={() => {
        props.onClick(
          history,
          props.view === "Users" ? "add-new-user" : "location"
        );
      }}
      size="sm"
      className="btn--primary"
    >
      {props.buttonText}
    </Button>
  );
};

const AuditHeader = props => {
  return (
    <Row noGutters className="dashboard-header">
      <Col className="dashboard-header-col">
        <Row className="dashboard-header-col__row">
          <Col>
            <p>{props.text}</p>
          </Col>
        </Row>
      </Col>
      <Col xs="auto">
        <Row className="dashboard-header-col__row">
          <Col xs="auto">
            <DropdownButton title="All Outlets" size="sm">
              <DropdownItem>All Outlets</DropdownItem>
              <DropdownItem>Validated Locations</DropdownItem>
              <DropdownItem>Unvalidated Locations</DropdownItem>
            </DropdownButton>
          </Col>
          <Col xs="auto" className="datepicker-col">
            <DatePicker id={props.view} />
          </Col>
          <Col xs="auto">
            <DropdownButton title="All Regions" size="sm">
              <DropdownItem>All Regions</DropdownItem>
              <DropdownItem>GB - Great Britain</DropdownItem>
              <DropdownItem>DE - Germany</DropdownItem>
              <DropdownItem>FR - France</DropdownItem>
              <DropdownItem>FR - Spain</DropdownItem>
            </DropdownButton>
          </Col>
          <Col xs="auto">
            <DropdownButton title="All Audit Types" size="sm">
              <DropdownItem>All Audit Types</DropdownItem>
              <DropdownItem>Marine</DropdownItem>
              <DropdownItem>Industrial</DropdownItem>
              <DropdownItem>Lawn</DropdownItem>
            </DropdownButton>
          </Col>
          <Col xs="auto">
            <Button size="sm" className="export-btn">
              Export Data
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const OutletUsersHeader = props => {
  return (
    <Row noGutters className="dashboard-header">
      <Col xs="auto" className="dashboard-header-col">
        <Row className="dashboard-header-col__row">
          <Col>
            <p>{props.text}</p>
          </Col>
          <Col xs="auto">
            <HeaderButton
              onClick={props.changeView}
              view={props.text}
              buttonText={
                props.text === "Users" ? "Add New User" : "Add New Location"
              }
            />
          </Col>
        </Row>
      </Col>
      <Col xs="auto">
        <Row className="dashboard-header-col__row">
          <Col xs="auto">
            <DropdownButton title={props.currentOutlet} size="sm">
              <DropdownItem
                  onSelect={() => {
                    props.setCurrentOutlet('All Outlets');
                  }}
              >
                All Outlets
              </DropdownItem>
              <DropdownItem
                  onSelect={() => {
                    props.setCurrentOutlet('Validated Outlets');
                  }}
              >
                Validated Outlets
              </DropdownItem>
              <DropdownItem
                  onSelect={() => {
                    props.setCurrentOutlet('Unvalidated Outlets');
                  }}
              >
                Unvalidated Outlets
              </DropdownItem>
            </DropdownButton>
          </Col>
          <Col xs="auto">
            <DropdownButton
              title={
                props.currentRegion && props.currentRegion.id !== 0
                  ? `${props.currentRegion.name}`
                  : "All Regions"
              }
              size="sm"
            >
              <DropdownItem
                onSelect={() => {
                  props.setCurrentRegion({ id: 0 });
                }}
              >
                All Regions
              </DropdownItem>
              {props.regions.map(region => {
                return (
                  <DropdownItem
                    key={`region-${region.id}`}
                    onSelect={() => {
                      props.setCurrentRegion(region);
                      props.getPage(1);
                    }}
                  >
                    {region.code} - {region.name}
                  </DropdownItem>
                );
              })}
            </DropdownButton>
          </Col>
          <Col xs="auto">
            <CSVLink filename={"OutletLocations-"+moment().format('YYYY-MM-DD hh:mm')+".csv"} style={{"backgroundColor": "rgba(0,0,0,0)"}} data={props.exportData} target="_blank" >
              <Button size="sm" className="export-btn">
                Export Data
              </Button>
            </CSVLink>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RegularHeader = props => {
  return (
    <Row noGutters className="dashboard-header">
      <Col xs="auto" className="dashboard-header-col">
        <p>{props.text}</p>
      </Col>
      <Col>
        {props.viewAll ? (
          <HeaderButton
            buttonText="View All"
            onClick={() => props.handleShowAll(props.type)}
          />
        ) : null}
      </Col>
    </Row>
  );
};

const DashboardHeader = props => {
  const returnComponent = (view, changeV) => {
    switch (view) {
      case "outlets":
        return (
          <OutletUsersHeader
            exportData={props.exportData}
            text="Outlet Locations"
            changeView={changeV}
            regions={props.regions}
            currentRegion={props.currentRegion}
            currentOutlet={props.currentOutlet}
            setCurrentRegion={props.setCurrentRegion}
            setCurrentOutlet={props.setCurrentOutlet}
            getPage={props.getPage}
          />
        );
      case "audits":
        return <AuditHeader text={props.text} view={view} />;
      default:
        return (
          <RegularHeader
            type={props.type}
            handleShowAll={props.handleShowAll}
            text={props.text}
            viewAll={props.viewAll}
          />
        );
    }
  };
  return returnComponent(props.view, props.changeView);
};

export default DashboardHeader;
