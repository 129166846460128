export const get = async (url, isAuth = true) => {
  try {
    const response = !isAuth
      ? await fetch(`${process.env.REACT_APP_API_URL}/api/${url}`)
      : await fetch(`${process.env.REACT_APP_API_URL}/api/${url}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });

    if (!response.ok) {
      localStorage.removeItem("token");
      console.error(response.status, response.statusText);
      return false;
    }

    const data = await response.json();
    // if it gets a 401 redirect to login page and delete the token

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const post = async (url, data, isAuth = true) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/${url}`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();

    return json;
  } catch (error) {
    console.error("Error:", error);

    return;
  }
};

export const put = async (url, data, isAuth = true) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/${url}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();

    return json;
  } catch (error) {
    console.error("Error:", error);

    return;
  }
};

export const del = async (url, isAuth = true) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/${url}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (!response.ok) {
      localStorage.removeItem("token");
      console.error(response.status, response.statusText);
      return false;
    }

    const data = await response.json();
    // if it gets a 401 redirect to login page and delete the token

    return data;
  } catch (error) {
    console.log(error);
  }
};

export default { get, post, put, del };
