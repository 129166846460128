import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const Standard = () => {
  return (
    <thead className="data-title">
      <tr>
        <th></th>
        <th>
          <p>Outlet Name</p>
        </th>
        <th>
          <p>Address</p>
        </th>
        <th>
          <p>Region</p>
        </th>
        <th>
          <p>Audit Date</p>
        </th>
        <th>
          <p>Marine</p>
        </th>
        <th>
          <p>Industrial</p>
        </th>
        <th>
          <p>L&G</p>
        </th>
        <th>
          <p>Action</p>
        </th>
      </tr>
    </thead>
  );
};

const Outlets = (props) => {
  return (
    <thead className="data-title">
      <tr>
        <th></th>
        <th>
          <p>Outlet Name</p>
        </th>
        <th>
          <p>Address 1</p>
        </th>
        <th>
          <p>Town</p>
        </th>
        <th>
          <p>County</p>
        </th>
        <th>
          <p>Postcode</p>
        </th>
        <th>
          <p>Region</p>
        </th>
        <th>
          <p>Action</p>
        </th>
      </tr>
    </thead>
  );
};

const Users = () => {
  return (
    <thead className="data-title">
      <tr>
        <th>
          <p>Surname</p>
        </th>
        <th>
          <p>First Name</p>
        </th>
        <th>
          <p>Email Address</p>
        </th>
        <th>
          <p>Region</p>
        </th>
        <th>
          <p>User Type</p>
        </th>
        <th>
          <p>Action</p>
        </th>
      </tr>
    </thead>
  );
};

const Audit = (props) => {
  return (
    <Row noGutters className="data-title audit-title-row">
      <Col xs="2" className="audit-title-first">
        <p>{props.text}</p>
      </Col>
      <Col>
        <p>
          {props.passRate === "N/A"
            ? "No Audit Data Collected"
            : `${props.passRate}/${props.total} Passed`}
        </p>
      </Col>
      <Col xs="auto">
        {props.passRate === "N/A" ? null : (
          <Button
            onClick={() => props.toggleShow(props.text)}
            className="expand-btn"
          >
            Expand {props.show ? <FaAngleUp /> : <FaAngleDown />}
          </Button>
        )}
      </Col>
    </Row>
  );
};

const TitleRow = (props) => {
  if (props.view === "users") {
    return <Users />;
  } else if (props.view === "audit") {
    return (
      <Audit
        text={props.text}
        show={props.show}
        toggleShow={props.toggleShow}
        passRate={props.passRate}
        total={props.total}
      />
    );
  } else if (props.view === "outlets") {
    return <Outlets />;
  } else {
    return <Standard />;
  }
};

export default TitleRow;
