import React, { Component } from 'react';
import { connect } from 'react-redux';
import Login from './Login';
import { forgotPassword } from '../actions';

const mapStateToProps = (state) => {
    return {
      forgotPassword: state.login.forgotPassword
    };
}

class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.toggleForgot = this.toggleForgot.bind(this);
    }

    toggleForgot () {
        this.props.dispatch(forgotPassword());
    }

    render() {
        return <Login forgot={this.props.forgotPassword} toggleForgot={this.toggleForgot} />
    }
}

export default connect(mapStateToProps)(LoginComponent);