import React from "react";
import { Col, Row } from "react-bootstrap";

const AuditStatus = ({audits}) => {
    return (
    <div className="data-body audit-status">
      <div className="data-title">
        <p>Audited This Year</p>
      </div>
      <div className={audits.auditedPercentage > 49 ? 'pass' : 'fail'}>
        <Row noGutters>
          <Col className="percentage">{audits.auditedPercentage}%</Col>
        </Row>
        <Row noGutters>
          <Col>{`${audits.thisYearSoFar} outlets audited in ${new Date().getFullYear()} so far`}</Col>
        </Row>
        <Row noGutters>
          <Col>{`${audits.notYetAudited} not yet audited`}</Col>
        </Row>
      </div>
    </div>
  );
};

export default AuditStatus;
