import React, { Component } from "react";
import "./index.css";
import Audits from "./Audits";
import Audit from "./Audits/Audit";
import Dashboard from "./Dashboard";
import OutletLocations from "./OutletLocations";
import Location from "./OutletLocations/Location";
import User from "./Users/Users"
import EditPage from "../shared/EditPage";
import Users from "./Users";
import Support from "./Support";
import LoginComponent from "../Login";
import ChangePasswordComponent from "../ChangePassword";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import Navigation from "../Dashboard/Navigation";

/* this will have all the data as the parent of the search bar, 
the function of the updating of the data will go here and be passed to children.
When the endpoint is done you will hit the endpoint and pass the data to the children. */

/* this is where the data will be retrieved and set from the end point.

Start by getting the data from the locations end point and populate the component 
get data set data using state
pass to children and they use state as props. 
NEVER CHANGE THE PROP DIRECTLY ONLY CHANGE A STATE TO CHANGE A PROP

*/

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
          localStorage.getItem('token') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export class ViewsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: !!localStorage.getItem('token'),
      currentLocations: []
    };
  }

  componentWillUpdate() {}

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({
        isLoggedIn: !!localStorage.getItem('token'),
      });
    });
  }

  render() {
    return (
      <>
        {this.state.isLoggedIn && <Navigation />}
        <Switch>
          <Route exact path="/login">
            <LoginComponent />
          </Route>
          <Route path="/change-password">
            <ChangePasswordComponent />
          </Route>
          <PrivateRoute path="/location/:id?">
            <Location />
          </PrivateRoute>
          <PrivateRoute path="/user/:id?">
            <User />
          </PrivateRoute>
          <PrivateRoute path="/audit/:id?">
            <Audit />
          </PrivateRoute>
          <PrivateRoute path="/dashboard">
            <Dashboard />;
          </PrivateRoute>

          <PrivateRoute path="/outlet-locations">
            <OutletLocations />
          </PrivateRoute>
          <PrivateRoute path="/audits">
            <Audits />
          </PrivateRoute>
          <PrivateRoute path="/users">
            <Users />
          </PrivateRoute>
          <PrivateRoute path="/editLocation">
            <EditPage version="edit-location" />
          </PrivateRoute>
          <PrivateRoute path="/addNewLocation">
            <EditPage version="add-new-location" />
          </PrivateRoute>
          <PrivateRoute path="/user">
            <EditPage version="add-new-user" />
          </PrivateRoute>
          <PrivateRoute path="/user/:id?">
            <EditPage version="edit-user" />
          </PrivateRoute>
          {/*<PrivateRoute path="/edit-user">*/}
          {/*  <EditPage version="edit-user" />*/}
          {/*</PrivateRoute>*/}
          <PrivateRoute path="/support">
            <Support />
          </PrivateRoute>
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        </Switch>
      </>
    );
  }
}

export default withRouter(ViewsComponent);
