import React, { Component } from "react";
import Request from "../../utils/request";

import { withRouter } from "react-router-dom";
import {
    Col,
    Row,
    Form,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import {
    DeleteUserButton,
    SaveChangesButton,
    AddUserButton,
} from "../../shared/buttons";

import DeleteModal from "../../shared/DeleteModal";

const UserForm = props => {
    return (
        <Col>
            <Row noGutters className="dashboard-header">
                <Col>
                    <Row noGutters>
                        <p className="black-title">Users</p>
                        <span className="angle-right">
                              <FaAngleRight />
                            </span>
                        <p className="red-title">
                            {
                                (props.isUser) ?
                                    <span>Edit User</span> :
                                    <span>Create User</span>
                            }
                        </p>
                    </Row>
                </Col>
            </Row>
            <Row noGutters>
                <Col lg="6" className="edit-container">
                    <Form>
                        <FormGroup controlId="formGroupFirstName">
                            <FormLabel>First Name</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="First Name"
                                onChange={(e) => {props.handleUpdateInput(e, 'first_name')}}
                                value={props.user.first_name}
                            />
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup controlId="formGroupSurname">
                            <FormLabel>Surname</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="Surname"
                                onChange={e => props.handleUpdateInput(e, "last_name")}
                                value={props.user.last_name}
                            />
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup controlId="formGroupEmail">
                            <FormLabel>Email Address</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="Email address"
                                onChange={e => props.handleUpdateInput(e, "email")}
                                value={props.user.email}
                            />
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup controlId="formGroupPassword">
                            <FormLabel>Password</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="Password"
                                onChange={e => props.handleUpdateInput(e, "password")}
                                value={props.user.password}
                            />
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup controlId="formGroupPasswordConfirmation">
                            <FormLabel>Password Confirmation</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="Password Confirmation"
                                onChange={e => props.handleUpdateInput(e, "password_confirmation")}
                                value={props.user.password_confirmation}
                            />
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup controlId="formGroupRegion">
                            <FormLabel>Country/Region</FormLabel>
                            <FormControl
                                as="select"
                                onChange={e => props.handleUpdateInput(e, "region")}
                                value={props.selectedRegion.id}
                            >
                                <option key={`userRegionDefault`} value={0} disabled>Choose a country/region...</option>
                                {props.regions.map((option, index) => {
                                    return (
                                        <option key={`locationRegion${index}`} value={option.id}>
                                            {option.name}
                                        </option>
                                    );
                                })}
                            </FormControl>
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup controlId="formGroupArea">
                            <FormLabel>Area</FormLabel>
                            <FormControl
                                as="select"
                                onChange={e => props.handleUpdateInput(e, "area")}
                                value={props.selectedArea.id}
                            >
                                <option key={`userAreaDefault`} value={0} disabled>
                                    Choose an area...
                                </option>
                                {props.selectedRegion.areas.map((area, index) => {
                                    return (
                                        <option key={`userRegion${index}`} value={area.id}>
                                            {area.name}
                                        </option>
                                    );
                                })}
                            </FormControl>
                        </FormGroup>
                    </Form>
                    {
                        (!props.isUser) ?
                            <Form>
                                <FormGroup controlId="formGroupRole">
                                    <FormLabel>Role</FormLabel>
                                    <FormControl
                                        as="select"
                                        onChange={e => props.handleUpdateInput(e, "role")}
                                        value={props.selectedRole.id}
                                    >
                                        <option key={`userRoleDefault`} value={0} disabled>
                                            Choose a role...
                                        </option>
                                        {props.roles.map((role, index) => {
                                            return (
                                                <option key={`userRole-${index}`} value={role.id}>
                                                    {role.title}
                                                </option>
                                            );
                                        })}
                                    </FormControl>
                                </FormGroup>
                            </Form>
                            :
                            <></>
                    }
                    <Row noGutters className="bottom-buttons">
                        <Col xs="6">
                            {props.isUser ? <DeleteUserButton onClick={() => props.handleDelete()} /> : null}
                        </Col>
                        <Col xs="6" className="save-change">
                            {props.isUser ? (
                                <SaveChangesButton onClick={() => props.handleSubmit()} {...props} />
                            ) : (
                                <AddUserButton onClick={() => props.handleSubmit()} {...props} />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

class User extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                first_name: "",
                last_name: "",
                email: "",
                area: {
                    id: 0,
                    name: "",
                    region: {
                        id: 0,
                        name: "",
                        code: ""
                    }
                },
                role: {
                    id: 0,
                    name: "",
                    title: ""
                },
            },
            isUser: false,
            regions: [
                {
                    name: "",
                    code: "",
                    id: 0,
                    areas: [
                        {
                            id: 0,
                            name: "",
                            regions_id: 0
                        }
                    ]
                }
            ],
            areas: [
                {
                    id: 0,
                    name: "",
                    regions_id: 0
                }
            ],
            roles: [],
            selectedRegion: {
                name: "",
                code: "",
                id: 0,
                areas: [
                    {
                        id: 0,
                        name: "",
                        regions_id: 0
                    }
                ]
            },
            selectedArea: {
                id: 0,
                name: "",
                regions_id: 0
            },
            selectedRole: {
                id: 0,
                name: "",
                title: ""
            },
            deleteModal: {
                show: false,
            }
        };
    }

    componentDidMount = async() => {
        const { params } = this.props.match;

        let user = await this.getUser();

        let regions = await this.getRegions();
        let selectedRegion = this.getSelectedRegion(user.area.region.id, regions);

        let areas = this.getAreas(selectedRegion);
        let selectedArea = user.area;

        let roles = await this.getRoles();

        this.setState({
            user,
            isUser: !!params.id,
            regions,
            areas,
            selectedRegion,
            selectedArea,
            roles
        });
    }

    getUser = async () => {
        const { params } = this.props.match;

        if(!params.id)
        {
            return { ...this.state.user }
        }

        const currentUser = await Request.get(`users/${parseInt(params.id)}`);
        return (currentUser.data.length) ? currentUser.data[0] : {...this.state.user};
    };
    getRoles = async() => {
        const roles = await Request.get("roles");
        return roles.data
    }
    getRegions = async() => {
        const regions = await Request.get("regions");
        return regions.data
    }
    getAreas = region => {
        return region.areas.map(area => area);
    };

    getSelectedRegion = (regionId, regions) => {
        regionId = parseInt(regionId);

        if (regionId === 0) {
            return { ...this.state.regions[0] };
        }

        return regions.find(region => regionId === region.id);
    };
    getSelectedRole = (roleId, roles) => {
        roleId = parseInt(roleId);

        if (roleId === 0) {
            return { ...this.state.roles[0] };
        }

        return roles.find(role => roleId === role.id);
    };
    getSelectedArea = (areaId, areas) => {
        areaId = parseInt(areaId);
        if (areaId === 0) {
            return {
                id: 0,
                name: "",
                regions_id: 0
            };
        }

        return areas.find(area => areaId === area.id);
    };

    handleUpdateInput = (e, type) => {

        let user = { ...this.state.user };
        let selectedRegion = { ...this.state.selectedRegion };
        let selectedArea = { ...this.state.selectedArea };
        let selectedRole = { ...this.state.selectedRole };
        let regions = [ ...this.state.regions ];

        switch (type) {
            case "first_name":
                user.first_name = e.target.value;
                break;
            case "last_name":
                user.last_name = e.target.value;
                break;
            case "email":
                user.email = e.target.value;
                break;
            case "region":
                selectedRegion = this.getSelectedRegion(e.target.value, regions);
                user.areas_regions_id = selectedRegion.id;
                user.areas_id = 0;
                selectedArea = this.getSelectedArea(0, regions);
                break;
            case "area":
                selectedArea = this.getSelectedArea(e.target.value, selectedRegion.areas);
                user.areas_id = selectedArea.id;
                user.area = selectedArea;
                break;
            case "role":
                selectedRole = this.getSelectedRole(e.target.value, this.state.roles);
                user.role = selectedRole;
                user.roles_id = selectedRole.id;
                break;
            case "password":
                user.password = e.target.value;
                break;
            case "password_confirmation":
                user.password_confirmation = e.target.value;
                break;
            default:
                break;
        }

        this.setState({ user, selectedRegion, selectedArea, selectedRole });
    };
    handleSubmit = async () => {
        const user = { ...this.state.user };

        if(this.state.isUser && user.areas_id !== 0){
            await Request.put(`users/${user.id}`, user).then((response) => {
                if(response.status === 'success')
                {
                    alert('Updated User!')
                }
            });
            return;
        }

        if(!this.state.isUser){
            const { password, password_confirmation } = this.state.user;
            if (!password || password !== password_confirmation) {
                return;
            }

            await Request.post(`auth/register`, this.state.user).then((response) => {
                if(response.status === 'success')
                {
                    this.setState({
                        user: {
                            first_name: "",
                            last_name: "",
                            password: '',
                            password_confirmation: '',
                            email: "",
                            area: {
                                id: 0,
                                name: "",
                                region: {
                                    id: 0,
                                    name: "",
                                    code: ""
                                }
                            },
                            role: {
                                id: 0,
                                name: "",
                                title: ""
                            },
                        },
                        selectedRegion: {
                            name: "",
                            code: "",
                            id: 0,
                            areas: [
                                {
                                    id: 0,
                                    name: "",
                                    regions_id: 0
                                }
                            ]
                        },
                        selectedArea: {
                            id: 0,
                            name: "",
                            regions_id: 0
                        },
                        selectedRole: {
                            id: 0,
                            name: "",
                            title: ""
                        },
                    })
                    alert('Created User!')
                }
            })
        }
    };
    handleDeleteUser = async () => {
        await Request.del(`users/${this.state.user.id}`).then((response) => {
            if(response.status === 'success')
            {
                alert('Deleted User!');
                this.props.history.push('/users');
            }
        })
    }

    openDeleteModal = () => {
        this.setState({
            deleteModal: {
                show: true
            }
        })
    }
    closeDeleteModal = () => {
        this.setState({
            deleteModal: {
                show: false
            }
        })
    }

    render() {
        return (
            <Col className="view-col">
                <UserForm
                    user={this.state.user}
                    roles={this.state.roles}
                    regions={this.state.regions}
                    password={this.state.password}
                    selectedRegion={this.state.selectedRegion}
                    selectedArea={this.state.selectedArea}
                    selectedRole={this.state.selectedRole}
                    isUser={this.state.isUser}

                    handleUpdateInput={(e, type) => this.handleUpdateInput(e, type)}
                    handleSubmit={() => this.handleSubmit()}
                    handleDelete={() => this.openDeleteModal()}
                />
                <DeleteModal
                    show={this.state.deleteModal.show}
                    handleClose={() => { this.closeDeleteModal() }}
                    handleDelete={() => { this.handleDeleteUser() }}
                />
            </Col>
        );
    }
}

export default withRouter(User);