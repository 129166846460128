import React from 'react';
import { Col, Pagination } from 'react-bootstrap';

const PaginationEl = (props) => {
    let active = props.page;
    const { data } = props;
    let items = [];
    for (let number = 1; number <= data.length; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => {props.onClick(number)}}>
            {number}
            </Pagination.Item>
        );
    };

    if (active >= 5) {
        items = items.slice(active - 5, active + 5);
    } else if (active + 10 <= data.length){
            items = items.slice(0, 10);
    } else if (active + 10 > data.length) {
        items = items.slice(data.length - 10, data.length);
    }

    return ( 
        <Col>
            <Pagination>{ props.page > 1 ? <Pagination.First onClick={() => props.firstPage()} /> :  null}{ items }{ props.page !== props.pageLen ? <Pagination.Last onClick={() => props.lastPage(props.pageLen)}/> : null}</Pagination>
        </Col>
    );
};

export default PaginationEl;